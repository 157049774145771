import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { AppContext } from 'shared/components/AppProvider';
import { trackEvent } from 'shared/helpers/tracking';

import { AppContext as AppContextType } from 'shared/types/AppProvider';

type DisplayFrequency = 'DEFAULT' | 'DAILY';

// How often to display a pop-up to the user. By default, it will not place any
// restrictions on how often different pop-ups are displayed. Display daily
// restricts it to at most 1 pop-up shown per day.
export const DISPLAY_FREQUENCY: {
  [key in DisplayFrequency]: DisplayFrequency;
} = {
  DEFAULT: 'DEFAULT',
  DAILY: 'DAILY',
};

export const NO_ACTIVE_MODAL = 'no_active_modal';

const LOCAL_STORAGE_KEY = 'pop-up-manager-last-popup-time';

const updateModalViewedTimestamp = (popUpComponentName: string) => {
  const object = {
    viewedPopUpName: popUpComponentName,
    timestamp: new Date().getTime(),
  };
  if (window.localStorage) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(object));
  }
};

const shouldShowPopup = (
  displayFrequency: DisplayFrequency,
  popUpToShow: string,
  activePopUpName: string
) => {
  if (activePopUpName === popUpToShow) {
    return true;
  }
  const lastReviewed = window.localStorage?.getItem(LOCAL_STORAGE_KEY);
  if (!lastReviewed || displayFrequency === DISPLAY_FREQUENCY.DEFAULT) {
    return true;
  }
  const { timestamp } = JSON.parse(lastReviewed);

  const daysAgoToCompare = Date.now() - 1000 * 3600 * 24;
  const lastReviewedTime = new Date(timestamp);
  return lastReviewedTime.getTime() < daysAgoToCompare;
};

export type PopUpType = [
  React.ComponentType<{
    softSuppress?: boolean;
    reportInitialDisplay(displayed: boolean): void;
  }>,
  string,
];
export type PopUpManagerProps = {
  displayFrequency: keyof typeof DISPLAY_FREQUENCY;
  popUps: PopUpType[];
  softSuppress?: boolean;
};
export const PopUpManager = ({
  displayFrequency = DISPLAY_FREQUENCY.DEFAULT,
  popUps,
  softSuppress = false,
  ...props
}: PopUpManagerProps) => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [shouldReport, setShouldReport] = useState(true);

  const { activeModal } = useContext(AppContext) as AppContextType;

  useEffect(() => {
    if (activeIndex >= popUps.length) {
      // did not display any pop-ups after location change
      setActiveIndex(0);
      activeModal.setModalName(NO_ACTIVE_MODAL);
      setShouldReport(false);
    }
  }, [
    location.pathname,
    location.hash,
    activeIndex,
    popUps.length,
    activeModal,
  ]);

  if (activeIndex >= popUps.length) {
    return null;
  }

  const [PopUpComponent, PopUpComponentName] = popUps[activeIndex];

  if (
    !shouldShowPopup(
      displayFrequency,
      PopUpComponentName,
      activeModal.modalName
    )
  ) {
    return null;
  }

  return (
    <PopUpComponent
      softSuppress={softSuppress}
      reportInitialDisplay={(displayed) => {
        if (shouldReport) {
          if (displayed) {
            trackEvent('View Pop-up', { pop_up_name: PopUpComponentName });
            activeModal.setModalName(PopUpComponentName);
            updateModalViewedTimestamp(PopUpComponentName);
          } else {
            setActiveIndex(activeIndex + 1);
          }
        }
      }}
      {...props}
    />
  );
};

export default PopUpManager;
