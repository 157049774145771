import React from 'react';
import { OtherLocationsTout } from 'registration/components/new_pages/OnboardingCurrentLocation/OtherLocationsTout';
import { Button } from 'shared/components/ihcl/button';
import { styled } from 'shared/components/ihcl/styled';

const RecentGradSubtitle = styled('div', {
  fontWeight: '900',
  marginTop: '20px',
});

type OtherLocationsProps = {
  isRecentGrad?: boolean;
  openToRelocation?: boolean;
  setOpenToRelocation: Function;
};
export const OtherLocations = ({
  openToRelocation = null,
  setOpenToRelocation,
  isRecentGrad = false,
}: OtherLocationsProps) => (
  <div id="other-locations">
    {!isRecentGrad && <OtherLocationsTout />}
    {isRecentGrad && (
      <>
        <RecentGradSubtitle>
          Are you interested in working in other cities or states?
        </RecentGradSubtitle>
        <p>
          Employers recruit for nurses with less than 1 year of experience
          across the U.S.
        </p>
      </>
    )}
    <Button
      onClick={() => setOpenToRelocation(false)}
      kind={openToRelocation === false ? 'primary' : 'tertiary'}
      style={{
        minWidth: '100px',
        marginRight: '32px',
      }}
    >
      No
    </Button>
    <Button
      onClick={() => setOpenToRelocation(true)}
      kind={openToRelocation === true ? 'primary' : 'tertiary'}
      style={{
        minWidth: '100px',
      }}
    >
      Yes
    </Button>
  </div>
);
