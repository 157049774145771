import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'shared/components/ihcl/styled';
import { isNative } from 'shared/helpers/native';
import PropTypes from 'prop-types';

import stepCompleteVideo from 'images/animated/step-complete.mp4';

export const Step = styled(
  'div',
  ({
    $theme,
    $isAnimating,
    $skipped,
    $stepComplete,
    $stepCompleteWrapper,
    $stepCurrent,
  }) => {
    let backgroundColor = $theme.colors.inputBackground;
    let boxShadow =
      '0px 4px 4px #FFFFFF, inset 0px 2px 1px rgba(0, 0, 0, 0.04);';
    const extraStyles = {};
    if ($stepCurrent) {
      backgroundColor = $theme.colors.accent30;
      boxShadow = 'none';
    }
    if ($stepComplete) {
      backgroundColor = $theme.colors.accent;
      boxShadow = 'none';
    }
    if ($stepCompleteWrapper) {
      backgroundColor = 'transparent';
      boxShadow = 'none';
      extraStyles.overflow = 'hidden';
      extraStyles.height = '36px';
      extraStyles.marginTop = '-13px';
      extraStyles.transition = '200ms all cubic-bezier(0,.5,.5,1)';
      if ($isAnimating) {
        extraStyles.minWidth = '30px';
      }
    }
    if ($skipped) {
      extraStyles.display = 'none';
    }
    return {
      backgroundColor,
      boxShadow,
      height: '4px',
      width: '100%',
      marginRight: '8px',
      minWidth: '8px',
      ':last-child': {
        marginRight: 0,
      },
      ...extraStyles,
    };
  }
);

export const ProgressStepsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '4px',
  overflow: 'visible',
  minWidth: 0,
});

export const AnimatedStepWrapper = styled('div', {
  width: '90px',
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
});

export const AnimatedStep = ({ setIsAnimating }) => {
  const videoRef = useRef(null);
  const animationEnded = () => {
    setIsAnimating(false);
  };
  useEffect(() => {
    // Everything around if statement
    if (videoRef && videoRef.current) {
      const { current } = videoRef;
      current.addEventListener('ended', animationEnded);

      return () => {
        current.removeEventListener('ended', animationEnded);
      };
    }
    return null;
  }, [videoRef]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      autoPlay
      muted={videoRef.current}
      playsInline
      width="90px"
      ref={videoRef}
    >
      <source src={stepCompleteVideo} type="video/mp4" />
    </video>
  );

  // return View;
};
AnimatedStep.propTypes = {
  setIsAnimating: PropTypes.func.isRequired,
};

export const ProgressSteps = ({
  totalSteps,
  currentStep,
  celebrateLastStep = true,
  skippedSteps = [],
}) => {
  const [isAnimating, setIsAnimating] = useState(celebrateLastStep);
  const steps = new Array(totalSteps).fill('').map((val, index) => {
    if (
      index === currentStep - 2 &&
      celebrateLastStep &&
      isAnimating &&
      !isNative()
    ) {
      return (
        <Step
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          $stepCompleteWrapper
          $isAnimating={isAnimating}
          $skipped={skippedSteps.includes(index + 1)}
        >
          <AnimatedStepWrapper>
            <AnimatedStep setIsAnimating={setIsAnimating} />
          </AnimatedStepWrapper>
        </Step>
      );
    }
    return (
      <Step
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        $stepCurrent={index === currentStep - 1}
        $stepComplete={index < currentStep - 1}
        $skipped={skippedSteps.includes(index + 1)}
      />
    );
  });
  return <ProgressStepsWrapper>{steps}</ProgressStepsWrapper>;
};
ProgressSteps.propTypes = {
  celebrateLastStep: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
  skippedSteps: PropTypes.arrayOf(PropTypes.number),
  totalSteps: PropTypes.number.isRequired,
};
