import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import addImage from 'images/icons/add.svg';
import arrowRightImage from 'images/icons/arrow-right.svg';
import fileImage from 'images/icons/file.svg';
import fileUploadImage from 'images/icons/file-upload.svg';
import sendImage from 'images/icons/send.svg';
import spinnerImage from 'images/icons/spinner-white.svg';

export default function IButton({
  children = null,
  className = null,
  icon = null,
  iconLocation = 'left',
  iconSrc = null,
  squareCorners = false,
  text = null,
  ...props
}) {
  let iconImgSrc = iconSrc;

  if (icon) {
    iconImgSrc = {
      add: addImage,
      arrowRight: arrowRightImage,
      file: fileImage,
      fileUpload: fileUploadImage,
      spinner: spinnerImage,
      send: sendImage,
    }[icon];
  }

  const buttonClassName = classnames(
    'i-button',
    { 'is-square-corners': squareCorners },
    className
  );

  return (
    <Button className={buttonClassName} {...props}>
      {iconImgSrc && iconLocation === 'left' && (
        <img className="icon icon--left" src={iconImgSrc} alt="" />
      )}
      {text || children}
      {iconImgSrc && iconLocation === 'right' && (
        <img className="icon icon--right" src={iconImgSrc} alt="" />
      )}
    </Button>
  );
}

IButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.oneOf([
    'add',
    'arrowRight',
    'file',
    'fileUpload',
    'send',
    'spinner',
  ]),
  iconLocation: PropTypes.oneOf(['left', 'right']),
  iconSrc: PropTypes.string,
  squareCorners: PropTypes.bool,
  text: PropTypes.string,
};
