// TODO: Convert to TypeScript - note, simply converting to typescript breaks
// our tests, specifically the location selection dropdown for recent grads.

import shuffle from 'just-shuffle';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { Center } from 'shared/components/ihcl/positioning';
import { Tag, SIZE as TAG_SIZE } from 'shared/components/ihcl/tag';
import { doGet } from 'shared/helpers/http';

import { AddLocation } from 'registration/components/new_pages/OnboardingLocationPersonalized/AddLocation';
import desiredLocationOptions from 'registration/constants/desiredLocationOptions';
import {
  Onboarding,
  OnboardingTitle,
  TagLocationPinIcon,
} from 'registration/containers/Onboarding';
import {
  DELIMITER,
  getShouldPreselectLocations,
  getUnbounceParamFromLocalforage,
  UnbounceParam,
} from 'registration/helpers/experiment/xena';
import { locationFromGooglePlace } from 'registration/helpers/talentProfileConversions';
import { pathToVariant } from 'shared/helpers/activateABTest';
import { useGoogleAutocompleteService } from 'shared/helpers/googlePlaces';
import { OnboardingContext } from '../../containers/Onboarding';

const NEW_LOCATIONS_COUNT = 10;

const getRecentGradLocations = async () => {
  const cities = await doGet('/talent_locations/recent_grad_cities').then(
    (resp) => resp.cities
  );
  return cities.map(([city, state]) => ({
    id: `${city}, ${state}, United States`,
    label: `${city}, ${state}`,
    city,
    state,
  }));
};

const filteredLocationValues = [
  ...desiredLocationOptions.filter((dl) =>
    Object.prototype.hasOwnProperty.call(dl, 'value')
  ),
];

const desiredLocationStateNameMap = desiredLocationOptions
  .filter((dl) => !Object.prototype.hasOwnProperty.call(dl, 'value'))
  .reduce(
    (stateMap, state) => ({
      ...stateMap,
      [state.section.toUpperCase()]: state.label,
    }),
    {}
  );

const desiredLocationValuesByState = {};
const desiredLocationValues = [];

filteredLocationValues.forEach((dl) => {
  const locationObj = {
    id: dl.value.address,
    label: `${dl.value.city}, ${dl.value.state}`,
    city: dl.value.city,
    state: dl.value.state,
  };
  desiredLocationValues.push(locationObj);
  const stateName = desiredLocationStateNameMap[dl.value.state];
  if (!desiredLocationValuesByState[stateName]) {
    desiredLocationValuesByState[stateName] = [];
  }
  desiredLocationValuesByState[stateName].push(locationObj);
});

desiredLocationValues.sort((a, b) => a.label.localeCompare(b.label));

const locationTagType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
});
const locationsType = PropTypes.arrayOf(locationTagType);

const LocationTags = ({ locations, removeTag }) => (
  <Center>
    {locations.map((location) => (
      <Tag
        $fadeIn
        $fullWidth
        key={location.id}
        size={TAG_SIZE.medium}
        onActionClick={() => removeTag(location)}
      >
        {location.zipCode && <TagLocationPinIcon />}
        {location.label}
      </Tag>
    ))}
  </Center>
);
LocationTags.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  removeTag: PropTypes.func.isRequired,
};

const NewLocationTags = ({ locations, moveTag }) => (
  <Center>
    {locations.map((location) => (
      <Tag
        key={location.id}
        closeable={false}
        kind="primary"
        size={TAG_SIZE.medium}
        onClick={() => moveTag(location)}
        variant="solid"
      >
        {location.label}
      </Tag>
    ))}
  </Center>
);
NewLocationTags.propTypes = {
  locations: locationsType.isRequired,
  moveTag: PropTypes.func.isRequired,
};

const NewLocations = ({ locations, moveTag, replaceLocations }) =>
  locations.newLocations.length > 0 && (
    <Center>
      <span style={{ fontWeight: 900 }}>Popular cities with nurses</span>
      <p>Tap to add cities you are interested in.</p>
      <NewLocationTags locations={locations.newLocations} moveTag={moveTag} />
      {locations.unfilteredCount > NEW_LOCATIONS_COUNT && (
        <Button kind="minimal" onClick={replaceLocations}>
          Show different cities
        </Button>
      )}
    </Center>
  );
NewLocations.propTypes = {
  locations: PropTypes.shape({
    newLocations: locationsType.isRequired,
    unfilteredCount: PropTypes.number,
  }).isRequired,
  moveTag: PropTypes.func.isRequired,
  replaceLocations: PropTypes.func.isRequired,
};

const SelectedLocations = styled('div', {});
const getNewLocations = (
  locationCount = NEW_LOCATIONS_COUNT,
  locationsToExclude,
  locationValues = desiredLocationValues
) => {
  const shuffledLocations = shuffle(locationValues);
  if (locationsToExclude && locationsToExclude.length > 0) {
    const filterdLocations = shuffledLocations.filter(
      (location) =>
        !locationsToExclude.some((lte) => {
          if (!lte) {
            return false;
          }
          return lte.id === location.id;
        })
    );
    const unfilteredCount = filterdLocations.length;
    return {
      newLocations: filterdLocations.splice(0, locationCount),
      unfilteredCount,
    };
  }

  const unfilteredCount = shuffledLocations.length;
  return {
    newLocations: shuffledLocations.splice(0, locationCount),
    unfilteredCount,
  };
};

const OnboardingLocation = ({
  nextAction = null,
  previousAction = null,
  stepNumber,
}) => {
  const { onboardingBasePath, onboardingData, setOnboardingData } =
    useContext(OnboardingContext);
  const { desiredLocations: selectedLocations, recent_grad: recentGrad } =
    onboardingData;
  const emptySelectedLocations =
    !selectedLocations || (selectedLocations && selectedLocations.length === 0);
  const [recentGradLocations, setRecentGradLocations] = useState(null);
  const hasRecentGradLocations =
    recentGradLocations && recentGradLocations.length > 0;
  const [newLocations, setNewLocations] = useState(
    getNewLocations(
      NEW_LOCATIONS_COUNT,
      selectedLocations,
      hasRecentGradLocations ? recentGradLocations : desiredLocationValues
    )
  );
  const [paramLocations, setParamLocations] = useState(null);

  const addTag = useCallback(
    (tagObj) => {
      setOnboardingData((data) => {
        if (
          data.desiredLocations.every((location) => location.id !== tagObj.id)
          // using data.desiredLocations instead of selectedLocations here to
          // eliminate extra dependency and excessive firing of below effect
        ) {
          return {
            ...data,
            desiredLocations: [...data.desiredLocations, tagObj],
          };
        }

        return data;
      });
    },
    [setOnboardingData]
  );

  const [shouldPreselectOtherLocations, setShouldPreselectOtherLocations] =
    useState(false);

  const variant = pathToVariant(onboardingBasePath);

  useEffect(() => {
    getShouldPreselectLocations(variant).then((value) =>
      setShouldPreselectOtherLocations(value)
    );
  }, [variant]);

  useEffect(() => {
    if (shouldPreselectOtherLocations) {
      // also get any locations we saved from unbound query param
      const getParamLocation = async () => {
        const paramValue = await getUnbounceParamFromLocalforage(
          UnbounceParam.desired_location
        );

        setParamLocations(paramValue);
      };

      getParamLocation();
    }
  });

  const autocompleteService = useGoogleAutocompleteService();

  const getResultsFromGoogle = async (input) => {
    const resp = await autocompleteService?.getPlacePredictions({
      input,
      componentRestrictions: { country: 'us' },
      types: ['(cities)'],
    });

    return resp?.predictions;
  };

  useEffect(() => {
    const addTagsFromGoogleMatches = async () => {
      paramLocations?.split(DELIMITER).forEach(async (paramLocation) => {
        if (paramLocation.trim()) {
          const results = await getResultsFromGoogle(paramLocation);

          if (results?.length > 0) {
            const locationValues = results.map(locationFromGooglePlace);
            const match = locationValues.find(
              (locationValue) =>
                locationValue?.label.replace(/ +/g, '').toLowerCase() ===
                paramLocation.replace(/ +/g, '').toLowerCase()
            );
            if (match) addTag(match);
          }
        }
      });
    };

    addTagsFromGoogleMatches();
  }, [paramLocations, addTag]);

  const replaceLocations = () => {
    const locationsToAppend = getNewLocations(
      NEW_LOCATIONS_COUNT,
      selectedLocations,
      hasRecentGradLocations ? recentGradLocations : desiredLocationValues
    );
    setNewLocations(locationsToAppend);
  };
  const removeTag = ({ id }) => {
    const filteredTags = selectedLocations.filter((tag) => tag.id !== id);
    setOnboardingData({
      ...onboardingData,
      desiredLocations: filteredTags,
    });
  };
  const replaceNewLocationTag = (oldLocation) => {
    let unfilteredCount;
    const locationValues = hasRecentGradLocations
      ? recentGradLocations
      : desiredLocationValues;
    const updatedNewLocations = newLocations.newLocations
      .map((location) => {
        if (location.id === oldLocation.id) {
          const {
            newLocations: newLocationsArr,
            unfilteredCount: newUnfilteredCount,
          } = getNewLocations(
            1,
            [...selectedLocations, ...newLocations.newLocations],
            locationValues
          );
          unfilteredCount = newUnfilteredCount;
          const newLocation = newLocationsArr[0];
          if (newLocation) {
            return newLocation;
          }
          return null;
        }
        return location;
      })
      .filter((location) => !!location);
    setNewLocations({
      newLocations: updatedNewLocations,
      unfilteredCount,
    });
  };
  const moveTag = (tag) => {
    replaceNewLocationTag(tag);
    addTag(tag);
  };

  if (recentGrad && recentGradLocations === null) {
    setRecentGradLocations(false);
    getRecentGradLocations().then((resp) => {
      if (resp && Array.isArray(resp)) {
        if (resp.length > 0) {
          setNewLocations(
            getNewLocations(NEW_LOCATIONS_COUNT, selectedLocations, resp)
          );
        }
        setRecentGradLocations(resp);
      }
    });
  }

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={emptySelectedLocations}
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['desiredLocations']}
    >
      <OnboardingTitle>Where would you like to work?</OnboardingTitle>
      <SelectedLocations>
        {selectedLocations && (
          <LocationTags locations={selectedLocations} removeTag={removeTag} />
        )}
        {!recentGrad && (
          <AddLocation
            addTag={addTag}
            defaultOptions={desiredLocationValuesByState}
            getResultsFromGoogle={getResultsFromGoogle}
            recentGrad={recentGrad}
          />
        )}
      </SelectedLocations>
      {newLocations?.newLocations &&
        !(recentGrad && recentGradLocations === false) && (
          <NewLocations
            locations={newLocations}
            moveTag={moveTag}
            replaceLocations={replaceLocations}
          />
        )}
      {recentGrad && (
        <AddLocation
          addTag={addTag}
          defaultOptions={desiredLocationValuesByState}
          getResultsFromGoogle={getResultsFromGoogle}
          recentGrad={recentGrad}
        />
      )}
    </Onboarding>
  );
};
OnboardingLocation.propTypes = {
  nextAction: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingLocation;
