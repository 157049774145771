import React from 'react';
import { useStyletron, withStyle, Theme } from 'shared/components/ihcl/styled';
import {
  getDefaultInputId,
  Caption,
  Label,
} from 'shared/components/ihcl/input';
import {
  Select as BaseSelect,
  StyledControlContainer as BaseStyledContrlContainer,
  StyledIconsContainer as BaseStyledIconsContainer,
  StyledValueContainer as BaseStyledValueContainer,
  SIZE,
} from 'baseui/select';
import StatefulContainer from 'baseui/select/stateful-select-container';
import { Tag, KIND as TAG_KIND } from './tag';

export const StyledControlContainer = withStyle(
  BaseStyledContrlContainer,
  ({ $multi, $size, $theme, $error }) => {
    let height = 'auto';
    if (!$multi) {
      height =
        $size === 'compact'
          ? $theme.sizing.inputCompactHeight
          : $theme.sizing.inputHeight;
    }

    const additionalStyles = $error
      ? {
          backgroundColor: $theme.colors.inputFillError,
        }
      : {};

    return {
      height,
      ...additionalStyles,
    };
  }
);

export { SIZE } from 'baseui/select';

export const StyledValueContainer = withStyle(
  BaseStyledValueContainer,
  ({ $theme, $hasLabel }: { $hasLabel: boolean; $theme: Theme }) => ({
    paddingLeft: $theme.sizing.inputPaddingLeft,
    paddingBottom: $hasLabel ? 0 : $theme.sizing.unit10,
  })
);

export const StyledCompactValueContainer = withStyle(
  BaseStyledValueContainer,
  ({ $theme }) => ({
    fontSize: '14px',
    paddingLeft: $theme.sizing.inputCompactPadding,
    paddingRight: $theme.sizing.inputCompactPadding,
  })
);

type SelectProps = {
  caption?: React.ReactNode;
  className?: string;
  creatable?: boolean;
  error?: string | boolean;
  id?: string;
  inputId?: string;
  instanceId?: string;
  label?: string;
  multi?: boolean;
  overrides?: object | null;
  size?: keyof typeof SIZE;
  value?: Array<any>;
  [key: string]: any;
};

export const Select = ({
  caption = null,
  className = '',
  creatable = false,
  error = false,
  id = null,
  inputId = null,
  instanceId = null,
  label = null,
  multi = false,
  overrides = null,
  size = SIZE.default,
  value = [],
  ...props
}: SelectProps) => {
  const IconsContainer = BaseStyledIconsContainer;
  let ValueContainer = StyledValueContainer;
  const [, theme] = useStyletron();
  const [isFocused, setIsFocused] = React.useState(false);

  let selectInputId = inputId;
  if (!selectInputId) {
    selectInputId = getDefaultInputId();
  }

  if (size === 'compact') {
    ValueContainer = StyledCompactValueContainer;
  }

  const placeHolder = label ? null : 'Select...';

  let labelTop = '0';
  if (label && !isFocused && value?.length === 0) {
    labelTop = '12px';
  }

  return (
    <>
      <BaseSelect
        creatable={creatable}
        id={id}
        multi={multi}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        placeholder={placeHolder}
        size={size}
        value={value}
        {...props}
        overrides={{
          Root: {
            props: {
              $size: size,
              className,
              ...(instanceId && { 'data-instance-id': instanceId }),
            },
          },
          ClearIcon: {
            style: () =>
              size === 'compact'
                ? {
                    padding: '8px',
                    width: '32px',
                    height: '32px',
                  }
                : {
                    padding: '8px',
                    width: '40px',
                    height: '40px',
                  },
          },
          ControlContainer: {
            props: {
              $multi: multi,
              $size: size,
              $theme: theme,
              $error: error,
            },
            component: StyledControlContainer,
          },
          IconsContainer,
          Input: {
            props: {
              id: selectInputId,
            },
          },
          SelectArrow: {
            style: () =>
              size === 'compact'
                ? {
                    padding: '8px 0',
                    width: '16px',
                    height: '32px',
                  }
                : {
                    padding: '8px 0',
                    width: '24px',
                    height: '40px',
                  },
          },
          ValueContainer: {
            component: ValueContainer,
            props: {
              $hasLabel: !!label,
            },
          },
          // eslint-disable-next-line react/no-unstable-nested-components
          Tag: (tagProps) => (
            <Tag {...tagProps} kind={TAG_KIND.accent} variant="solid" />
          ),
          SingleValue: {
            style: () => ({
              display: 'flex',
              alignItems: 'center',
            }),
          },
          ...(overrides || {}),
        }}
      />
      {label && (
        // @ts-ignore
        <Label $isFocused={isFocused} $top={labelTop} htmlFor={selectInputId}>
          {label}
        </Label>
      )}
      {/* @ts-ignore */}
      {caption && <Caption $error={error}>{caption}</Caption>}
    </>
  );
};

export const StatefulSelect = (props) => (
  <StatefulContainer {...props}>
    {/* @ts-ignore */}
    {(childrenProps) => <Select {...childrenProps} />}
  </StatefulContainer>
);
