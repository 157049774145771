import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { styled } from 'shared/components/ihcl/styled';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { Text } from 'shared/components/ihcl/text';
import { NurseProfile } from 'talent/types';
import { NurseSpecialtyDescription } from 'shared/types';
import State from 'shared/types/State';
import {
  Drawer,
  PrimaryButton,
  SecondaryButton,
} from 'shared/components/ihcl/drawer';
import { ANCHOR, SIZE } from 'baseui/drawer';
import { trackClick, trackComponentView } from 'shared/helpers/tracking';

const Body = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.unit24,
}));

const ProfileContent = styled('ul', ({ $theme }) => ({
  backgroundColor: $theme.colors.grayBackground,
  paddingTop: $theme.sizing.unit12,
  paddingBottom: $theme.sizing.unit6,
  paddingLeft: '0px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: $theme.sizing.unit8,
  gap: $theme.sizing.unit10,
  width: '100%',
}));

const ProfileSection = styled('li', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'top',
  gap: $theme.sizing.unit8,
  paddingBottom: $theme.sizing.unit10,
  paddingRight: $theme.sizing.unit20,
  paddingLeft: $theme.sizing.unit16,
  borderBottom: `1px solid ${$theme.colors.borderMuted}`,
  ':last-child': {
    borderBottom: 'none',
  },
  color: $theme.colors.grayPlaceholder,
}));

type SelfReviewModalProps = {
  closeModal: (eventName: string) => void;
  isOpen: boolean;
  nurseSpecialtyDescriptions: Array<NurseSpecialtyDescription>;
  states: Array<State>;
  talentProfile: NurseProfile;
};

const SelfReviewModal = ({
  closeModal,
  isOpen,
  nurseSpecialtyDescriptions,
  states,
  talentProfile,
}: SelfReviewModalProps) => {
  const history = useHistory();

  const currentPosition = talentProfile.work_experiences.find(
    (we) => we.end_date === null
  );

  const locations = [talentProfile.current_location]
    .concat(
      talentProfile.other_locations.map((ol) => `${ol.city}, ${ol.state}`)
    )
    .filter(Boolean);

  const specialties = talentProfile.nurse_specialties.map(
    (ns) =>
      nurseSpecialtyDescriptions.find(
        (nsd) => nsd.id === ns.nurse_specialty_description_id
      )?.description
  );

  const licenses = talentProfile.state_licenses.map(
    (l) => states.find((s) => s.id === l.state_id)?.abbreviation
  );

  useEffect(() => {
    if (isOpen) {
      trackComponentView('talent.screening.self_review');
    }
  }, [isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => closeModal('talent.screening.self_review.close')}
      size={SIZE.auto}
      anchor={ANCHOR.bottom}
      actions={[
        <PrimaryButton
          key="looks-good"
          onClick={() => closeModal('talent.screening.self_review.looks_good')}
        >
          Looks good!
        </PrimaryButton>,
        <SecondaryButton
          key="edit"
          endEnhancer={<LucideIcon name="Pencil" />}
          onClick={() => {
            trackClick('talent.screening.self_review.edit');
            history.push('/talent/profile');
          }}
        >
          Edit my profile
        </SecondaryButton>,
      ]}
      title="Is your profile currently up to date?"
    >
      <Body>
        <Text variant="ParagraphMedium" color="grayBodyCopy">
          To get you the best matches, please confirm the following details:
        </Text>
        <ProfileContent>
          <ProfileSection>
            <LucideIcon name="Briefcase" size="unit16" />
            <Text variant="LabelSmall" color="grayBodyCopy">
              Current position:{' '}
              <Text
                element="span"
                variant="LabelSmall"
                color={currentPosition ? 'grayBodyCopy' : 'negative'}
              >
                <strong>
                  {currentPosition
                    ? currentPosition.job_title
                    : 'Missing work experience'}
                </strong>
              </Text>
            </Text>
          </ProfileSection>
          <ProfileSection>
            <LucideIcon name="Sun" size="unit16" />
            <Text variant="LabelSmall" color="grayBodyCopy">
              Shift preferences:{' '}
              <Text
                element="span"
                variant="LabelSmall"
                color={talentProfile.shifts ? 'grayBodyCopy' : 'negative'}
              >
                <strong>
                  {talentProfile.shifts
                    ? talentProfile.shifts
                    : 'Missing shift preferences'}
                </strong>
              </Text>
            </Text>
          </ProfileSection>
          <ProfileSection>
            <LucideIcon name="MapPin" size="unit16" />
            <Text variant="LabelSmall" color="grayBodyCopy">
              Location preferences:{' '}
              <Text
                element="span"
                variant="LabelSmall"
                color={locations.length > 0 ? 'grayBodyCopy' : 'negative'}
              >
                <strong>
                  {locations.length === 0 && 'Missing location preferences'}
                  {locations.slice(0, 2).join(', ')}
                </strong>
              </Text>
              <Text element="span" variant="LabelSmall" color="grayPlaceholder">
                <strong>
                  {locations.length > 2
                    ? ` + ${locations.length - 2} more`
                    : ''}
                </strong>
              </Text>
            </Text>
          </ProfileSection>
          <ProfileSection>
            <LucideIcon name="IdCard" size="unit16" />
            <Text variant="LabelSmall" color="grayBodyCopy">
              License:{' '}
              <Text
                element="span"
                variant="LabelSmall"
                color={licenses.length > 0 ? 'grayBodyCopy' : 'negative'}
              >
                <strong>
                  {licenses.length === 0 && 'Missing licenses'}
                  {licenses.join(', ')}
                </strong>
              </Text>
            </Text>
          </ProfileSection>
          <ProfileSection>
            <LucideIcon name="Syringe" size="unit16" />
            <Text variant="LabelSmall" color="grayBodyCopy">
              Specialties:{' '}
              <Text
                element="span"
                variant="LabelSmall"
                color={specialties.length > 0 ? 'grayBodyCopy' : 'negative'}
              >
                <strong>
                  {specialties.length === 0 && 'Missing specialties'}
                  {specialties.slice(0, 2).join(', ')}
                </strong>
              </Text>
              <Text element="span" variant="LabelSmall" color="grayPlaceholder">
                <strong>
                  {specialties.length > 2
                    ? ` + ${specialties.length - 2} more`
                    : ''}
                </strong>
              </Text>
            </Text>
          </ProfileSection>
        </ProfileContent>
      </Body>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  nurseSpecialtyDescriptions: state.nurse_specialty_descriptions,
  states: state.states,
  talentProfile: state.talent_profile,
});

export default connect(mapStateToProps, {})(SelfReviewModal);
