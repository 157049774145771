import React from 'react';

import { styled, withStyle } from 'shared/components/ihcl/styled';
import {
  ANCHOR,
  StyledClose as BaseDrawerStyledClose,
  StyledDrawerBody as BaseStyledDrawerBody,
  StyledDrawerContainer as BaseStyledDrawerContainer,
  SIZE as DRAWER_SIZE,
  Drawer,
} from 'baseui/drawer';
import {
  StyledDialog as BaseStyledModalDialog,
  StyledClose as BaseStyledModalClose,
  SIZE as MODAL_SIZE,
  Modal,
  ROLE,
} from 'baseui/modal';

import { useViewportContext } from 'shared/providers/ViewportProvider';
import { StyleObject } from 'styletron-react';

export const FloatModalHeader = styled('div', ({ $theme }) => ({
  ...$theme.typography.HeadingSmall,
  fontWeight: 800,
  lineHeight: '120%',
  letterSpacing: '0.25px',
}));
export const FloatModalGraphic = styled('figure', {
  textAlign: 'center',
});
export const FloatModalBody = styled('div', ({ $theme }) => ({
  ...$theme.typography.ParagraphSmall,
  color: $theme.colors.bodyCopyGray,
}));
export const FloatModalFooter = styled('div', ({ $theme }) => ({
  // 48px target spacing - 16px gap from parent Body
  marginTop: $theme.sizing.scale900,
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.scale600,
}));

const styleCommonOuter = ({ $theme }) => ({
  borderStartEndRadius: $theme.sizing.scale800,
  borderStartStartRadius: $theme.sizing.scale800,
});
const styleCommonInner = ({
  $theme,
  $paddingTop,
  $paddingBottom,
  $paddingRight,
}): StyleObject => ({
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.scale600,

  // 24px padding + 24px for close button + 48px for content padding
  paddingTop: $paddingTop || $theme.sizing.scale2400,
  paddingBottom: $paddingBottom || $theme.sizing.scale1400,
  paddingLeft: $theme.sizing.scale800,
  paddingRight: $paddingRight || $theme.sizing.scale800,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
});

const DrawerContainer = withStyle(BaseStyledDrawerContainer, ({ $theme }) => ({
  ...styleCommonOuter({ $theme }),
  left: 'auto',
  [$theme.mediaQuery.sm]: {
    maxWidth: '375px',
  },
}));

const DrawerBody = withStyle<
  any,
  {
    $innerPaddingBottom: string;
    $innerPaddingRight: string;
    $innerPaddingTop: string;
  }
>(
  BaseStyledDrawerBody,
  ({ $theme, $innerPaddingBottom, $innerPaddingRight, $innerPaddingTop }) => ({
    ...styleCommonInner({
      $theme,
      $paddingRight: $innerPaddingRight,
      $paddingBottom: $innerPaddingBottom,
      $paddingTop: $innerPaddingTop,
    }),
  })
);

const ModalDialog = withStyle<
  any,
  {
    $innerPaddingBottom: string;
    $innerPaddingRight: string;
    $innerPaddingTop: string;
  }
>(
  BaseStyledModalDialog,
  ({ $theme, $innerPaddingBottom, $innerPaddingRight, $innerPaddingTop }) => ({
    ...styleCommonOuter({ $theme }),
    borderEndEndRadius: $theme.sizing.scale800,
    borderEndStartRadius: $theme.sizing.scale800,
    maxWidth: '500px',
    ...styleCommonInner({
      $theme,
      $paddingBottom: $innerPaddingBottom,
      $paddingRight: $innerPaddingRight,
      $paddingTop: $innerPaddingTop,
    }),
    paddingLeft: $theme.sizing.scale1200, // 24px padding + 24px for additional padding
    paddingRight: $theme.sizing.scale1200,
  })
);

const styleCloseCommon = ({ $theme }) => ({
  color: $theme.colors.grayLabel,
  fill: $theme.colors.grayLabel,
  width: $theme.sizing.scale800,
  outline: $theme.colors.grayLabel,
  height: $theme.sizing.scale800,
  top: $theme.sizing.scale800,
  right: $theme.sizing.scale800,
});
const DrawerClose = withStyle(BaseDrawerStyledClose, styleCloseCommon);
const ModalClose = withStyle(BaseStyledModalClose, styleCloseCommon);

type FloatModalProps = {
  zIndex?: number;
  children: JSX.Element | JSX.Element[];
  isOpen?: boolean;
  onClose?: () => void;
  animate?: boolean;
  autoFocus?: boolean;
  closeable?: boolean;
  innerPaddingTop?: string;
  innerPaddingBottom?: string;
  innerPaddingRight?: string;
};

export const FloatModal = ({
  children,
  zIndex = 1200, // Default to overlay elements on /talent/profile
  isOpen = null,
  onClose = null,
  animate = true,
  autoFocus = true,
  closeable = true,
  innerPaddingTop = '',
  innerPaddingBottom = '',
  innerPaddingRight = '',
}: FloatModalProps): JSX.Element => {
  const { breakpoint } = useViewportContext();
  return breakpoint === 'small' ? (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      animate={animate}
      autoFocus={autoFocus}
      closeable={closeable}
      size={DRAWER_SIZE.auto}
      anchor={ANCHOR.bottom}
      overrides={{
        Root: {
          style: {
            zIndex,
            display: 'flex',
            justifyContent: 'center',
          },
        },
        DrawerContainer,
        DrawerBody: {
          props: {
            $innerPaddingRight: innerPaddingRight,
            $innerPaddingBottom: innerPaddingBottom,
            $innerPaddingTop: innerPaddingTop,
          },
          component: DrawerBody,
        },
        Close: DrawerClose,
      }}
    >
      {children}
    </Drawer>
  ) : (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      animate={animate}
      autoFocus={autoFocus}
      closeable={closeable}
      size={MODAL_SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: { style: { zIndex } },
        Dialog: {
          props: {
            $innerPaddingRight: innerPaddingRight,
            $innerPaddingBottom: innerPaddingBottom,
            $innerPaddingTop: innerPaddingTop,
          },
          component: ModalDialog,
        },
        Close: ModalClose,
      }}
    >
      {children}
    </Modal>
  );
};
