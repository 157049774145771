import React from 'react';
import { styled, useStyletron } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { UNSELECTED } from 'shared/components/ihcl/button_select';
import { Center } from 'shared/components/ihcl/positioning';
import { Footnote, FootnoteIndicator } from 'shared/components/ihcl/footnote';

import {
  OnboardingTitle,
  OnboardingSubtitle,
  OnboardingWrapper,
} from '../../containers/Onboarding';
import { testimonial as onboardingTestimonial } from './OnboardingTimelineIsolatedQuestions';

const RoleTypeWrapper = styled('div', ({ $theme }) => ({
  margiTop: $theme.sizing.unit32,
  marginRight: $theme.sizing.unit36,
  marginBottom: $theme.sizing.unit32,
  marginLeft: $theme.sizing.unit36,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  gap: $theme.sizing.unit32,
}));

const OnboardingPermanentJob = () => {
  const [, $theme] = useStyletron();

  const justifyContentStyle = { paddingTop: '78px' };

  return (
    <Center>
      <OnboardingWrapper
        $compactMaxWidth
        style={{ ...justifyContentStyle }}
        $justifyContent="space-between"
        $gap={$theme.sizing.unit32}
      >
        <div>
          <OnboardingTitle>
            What permanent job roles
            <FootnoteIndicator>*</FootnoteIndicator> are you interested in?
          </OnboardingTitle>
          <OnboardingSubtitle>
            We have 1500+ top-ranked hospitals actively hiring on our career
            marketplace
          </OnboardingSubtitle>
          <RoleTypeWrapper>
            <Button
              onClick={() =>
                window.location.assign(
                  `/talent_onboard/_/2?job_search_status=${UNSELECTED}`
                )
              }
              kind="tertiary"
            >
              Nursing
            </Button>
            <Button
              onClick={() =>
                window.location.assign(
                  `/tech_onboard/_/2?job_search_status=${UNSELECTED}`
                )
              }
              kind="tertiary"
            >
              Technician / Technologist
            </Button>
          </RoleTypeWrapper>
          <Footnote
            backgroundColor={$theme.colors.onboardingBackground}
            styles={{ maxWidth: '230px' }}
          >
            Full time, part time, and per diem options available.
          </Footnote>
        </div>
        {onboardingTestimonial}
      </OnboardingWrapper>
    </Center>
  );
};

export default OnboardingPermanentJob;
