import React, { useEffect, useState } from 'react';
import { Input } from 'shared/components/ihcl/input';

export const dateMatchRE = /([0-9]{2}?)(?:(?: )?(?:\/)(?: )?)([1-2][0-9]{3})/;
export const dateMatchYYYYMM =
  /([1-2][0-9]{3}?)(?:(?: )?(?:-)(?: )?)([0-9]{2})/;

export const getMonthTextFromDate = (isoDateText) =>
  isoDateText
    ? new Date(`${isoDateText}T00:00`).toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      })
    : null;

export const getDateFromMonthText = (monthYearString) => {
  const dateVal = monthYearString?.match(dateMatchYYYYMM);
  if (dateVal && dateVal.length > 0) {
    return new Date(`${dateVal[0]}-01T00:00`);
  }
  return null;
};

export enum Size {
  default = 'default',
  compact = 'compact',
  tiny = 'tiny',
}

type MonthPickerProps = {
  label?: string;
  max?: string;
  min?: string;
  onChange: Function;
  size?: Size;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  caption?: boolean;
};
export const MonthPicker: React.FC<MonthPickerProps> = ({
  label = undefined,
  max = undefined,
  min = undefined,
  onChange,
  size = Size.default,
  value = undefined,
  error = false,
  disabled = false,
  caption = null,
}) => {
  const [useMonthInput, setUseMonthInput] = useState(true);
  let dateValue = value;
  useEffect(() => {
    const testInput = document.createElement('input');
    try {
      testInput.type = 'month';
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e.message);
    }
    if (testInput.type === 'text') {
      setUseMonthInput(false);
    }
  });
  if (useMonthInput) {
    return (
      <Input
        type="month"
        label={label}
        min={min}
        max={max}
        onChange={(e) => {
          const changeValue = e.currentTarget.value;
          onChange(changeValue);
        }}
        size={size}
        value={!disabled ? value : ''}
        error={error}
        disabled={disabled}
        caption={caption}
      />
    );
  }
  let dateMatch = dateValue?.match(dateMatchYYYYMM);
  if (dateMatch) {
    const [, year, month] = dateMatch;
    dateValue = `${month} / ${year}`;
  }
  return (
    <Input
      value={!disabled ? dateValue : ''}
      label={label}
      mask="99 / 9999"
      size={size}
      onChange={(e) => {
        const changeValue = e.currentTarget.value;
        dateMatch = changeValue.match(dateMatchRE);
        let newMonth;
        let newYear;
        if (dateMatch) {
          [, newMonth, newYear] = dateMatch;
          onChange(`${newYear}-${newMonth}`);
          return;
        }
        onChange(changeValue);
      }}
      error={error}
      disabled={disabled}
      caption={caption}
    />
  );
};
