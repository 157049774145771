import React, { useContext } from 'react';
import { styled, withStyle } from 'shared/components/ihcl/styled';

import { ButtonSelect } from 'shared/components/ihcl/button_select';
import {
  FirstAidIcon,
  HospitalIcon,
  HospitalBedIcon,
  HospitalHouseIcon,
  HospitalPhoneIcon,
  NurseIcon,
} from 'shared/components/ihcl/icon';
import { Center } from 'shared/components/ihcl/positioning';
import simplePluralize from 'shared/helpers/simplePluralize';

import {
  Onboarding,
  OnboardingContext,
  OnboardingTitle,
  OnboardingSubtitle as OnboardingSubtitleBase,
} from 'registration/containers/Onboarding';

const OnboardingSubtitle = withStyle(OnboardingSubtitleBase, {
  marginBottom: '32px',
});

const IconWrapper = styled('div', {
  marginRight: '8px',
});

const getCareSettingIcon = (careSetting) => {
  switch (careSetting.short_name) {
    case 'acute_care': {
      return <HospitalIcon />;
    }
    case 'ambulatory': {
      return <FirstAidIcon />;
    }
    case 'home_health': {
      return <HospitalHouseIcon />;
    }
    case 'long_term_acute_care': {
      return <HospitalBedIcon />;
    }
    case 'skilled_nursing': {
      return <NurseIcon />;
    }
    case 'telehealth': {
      return <HospitalPhoneIcon />;
    }
    default: {
      return null;
    }
  }
};

const augmentCareSettings = (careSettings) => {
  const augmentedCareSettings = careSettings.map((careSetting) => ({
    ...careSetting,
    label: (
      <>
        <IconWrapper>{getCareSettingIcon(careSetting)}</IconWrapper>
        {careSetting.label}
      </>
    ),
  }));
  return augmentedCareSettings;
};

type OnboardingCareSettingsProps = {
  nextAction?: string;
  previousAction?: string;
  stepNumber: number;
};
const OnboardingCareSettings = ({
  nextAction = null,
  previousAction = null,
  stepNumber,
}: OnboardingCareSettingsProps) => {
  const {
    isLiteProfileActivating,
    onboardingData,
    onboardingOptions,
    setOnboardingData,
  }: any = useContext(OnboardingContext);
  if (isLiteProfileActivating && !onboardingData.hiring_activated) {
    setOnboardingData({ ...onboardingData, hiring_activated: true });
  }

  const { care_settings: careSettingsOrig } = onboardingOptions;
  const careSettings = augmentCareSettings(careSettingsOrig);
  const { care_settings: selectedCareSettings } = onboardingData;
  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }

  const isOptionSelected = (option) =>
    selectedCareSettings.some(
      (selectedCareSetting) => selectedCareSetting === option.value
    );

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={!selectedCareSettings || selectedCareSettings.length < 1}
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['care_settings', 'hiring_activated']}
    >
      <Center>
        <OnboardingTitle>
          Which care settings are you open to working in?
        </OnboardingTitle>
        <OnboardingSubtitle>
          {simplePluralize(selectedCareSettings.length, 'setting', 'settings')}{' '}
          selected
        </OnboardingSubtitle>
        <ButtonSelect
          multiSelect
          options={careSettings}
          onChange={(val, option, remove) =>
            remove
              ? setOnboardingData({
                  ...onboardingData,
                  care_settings: selectedCareSettings.filter(
                    (selectedCareSetting) =>
                      selectedCareSetting.value !== option.value
                  ),
                })
              : setOnboardingData({
                  ...onboardingData,
                  care_settings: isOptionSelected(option)
                    ? selectedCareSettings
                    : [...selectedCareSettings, option],
                })
          }
          selectedOptions={selectedCareSettings}
        />
      </Center>
    </Onboarding>
  );
};

export default OnboardingCareSettings;
