import React from 'react';
import { styled, Theme } from 'shared/components/ihcl/styled';

import { Button } from './button';
import { CloseIcon as UnsizedCloseIcon } from './icon';

export const UNSELECTED = 'unselected';

const ButtonWrapperVertical = styled(
  'div',
  ({
    $theme,
    $fillWidth,
    $rowGap,
  }: {
    $theme: Theme;
    $fillWidth: boolean;
    $rowGap: string | 0;
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: $fillWidth ? 'initial' : 'center',
    rowGap: $rowGap || $theme.sizing.scale600,
  })
);

const ButtonWrapperHorizontal = styled(
  'div',
  ({ $theme, $columnGap }: { $theme: Theme; $columnGap: string | 0 }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: $columnGap || $theme.sizing.scale600,
    rowGap: $theme.sizing.scale600,
    justifyContent: 'center',
    flexWrap: 'wrap',
  })
);

export type OptionType = {
  label: React.ReactNode;
  value: string | boolean | number;
};

const getSelectedOption = (selectedItem, options) => {
  if (!selectedItem || !options) {
    return null;
  }
  let selectedOption = options.find((option) => option.value === selectedItem);
  if (!selectedOption && selectedItem.value) {
    selectedOption = options.find(
      (option) => option.value === selectedItem.value
    );
  }
  return selectedOption;
};

const CloseIcon = () => <UnsizedCloseIcon height="12" width="12" />;

type ButtonSelectProps = {
  buttonComponent?: React.ElementType;
  buttonProps?: object;
  fillWidth?: boolean;
  itemGap?: string | number;
  multiSelect?: boolean;
  options: any[];
  onChange: (value: any, option: OptionType, isSelected: boolean) => void;
  orientation?: 'vertical' | 'horizontal';
  padding?: 'default' | 'minimal';
  radioSelect?: boolean;
  selectedOptions:
    | Array<string | number | boolean | OptionType>
    | string
    | boolean
    | number
    | OptionType;
  [key: string]: any;
};
export const ButtonSelect = ({
  buttonComponent = Button,
  buttonProps = null,
  fillWidth = false,
  multiSelect = false,
  options,
  onChange,
  orientation = 'vertical',
  padding = 'default',
  radioSelect = false,
  selectedOptions = [],
  itemGap = null,
}: ButtonSelectProps) => {
  const Wrapper =
    orientation === 'horizontal'
      ? ButtonWrapperHorizontal
      : ButtonWrapperVertical;
  const $fillWidth = orientation === 'vertical' && fillWidth;
  let selectedOptionObj = selectedOptions;
  const isSelected = (option) => {
    if (!Array.isArray(selectedOptions)) {
      return false;
    }
    return selectedOptions.some(
      (selectedOption) =>
        Object.prototype.hasOwnProperty.call(selectedOption, 'value') &&
        // @ts-ignore
        selectedOption.value === option.value
    );
  };
  const getButtonKind = (option) => {
    if (!selectedOptions || !(multiSelect || radioSelect)) {
      return 'tertiary';
    }
    if (isSelected(option)) {
      return 'primary';
    }
    return 'tertiary';
  };
  let visibleOptions;
  if (['string', 'boolean', 'number'].includes(typeof selectedOptionObj)) {
    selectedOptionObj = getSelectedOption(selectedOptionObj, options);
  }
  if (Array.isArray(selectedOptionObj) && !multiSelect) {
    selectedOptionObj = selectedOptionObj[0];
  }

  const ButtonOption = buttonComponent;
  if (
    selectedOptionObj &&
    // @ts-ignore
    selectedOptionObj.label &&
    !multiSelect &&
    !radioSelect
  ) {
    visibleOptions = (
      <Button
        onClick={() => onChange(null, null, null)}
        endEnhancer={CloseIcon}
        padding={padding}
      >
        {/* @ts-ignore */}
        {selectedOptionObj.label}
      </Button>
    );
  } else {
    visibleOptions = options.map((option) => (
      <ButtonOption
        padding={padding}
        // @ts-ignore
        key={option.value.toString()}
        // @ts-ignore
        onClick={() => onChange(option.value, option, isSelected(option))}
        kind={getButtonKind(option)}
        {...buttonProps}
      >
        {/* @ts-ignore */}
        {option.label}
      </ButtonOption>
    ));
  }
  return (
    // @ts-ignore
    <Wrapper $fillWidth={$fillWidth} $rowGap={itemGap} $columnGap={itemGap}>
      {visibleOptions}
    </Wrapper>
  );
};
