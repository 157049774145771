import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import { styled, withStyle } from 'shared/components/ihcl/styled';
import {
  jobSearchOptions,
  recentGradWithStudentOptions,
  startDateOptions,
  startDateOptionsNotLooking,
} from 'shared/constants/selectOptions';
import { trackClick } from 'shared/helpers/tracking';

import { Button } from 'shared/components/ihcl/button';
import {
  ButtonSelect,
  UNSELECTED,
  OptionType,
} from 'shared/components/ihcl/button_select';
import { DatePicker } from 'shared/components/ihcl/datepicker';
import { Center } from 'shared/components/ihcl/positioning';

import { OnboardingTitle } from 'registration/components/new_pages/OnboardingCurrentLocation/OnboardingTitle';
import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

import {
  BodyWrapper,
  ButtonWrapper,
  ErrorMessage,
  FooterWrapper,
  Prompt,
} from './styledComponents';

const minDate = new Date();
const maxDate = new Date(
  minDate.getFullYear() + 2,
  minDate.getMonth(),
  minDate.getDate()
);

const DatePickerWrapper = styled('div', () => ({
  marginTop: '1em',
  maxWidth: '250px',
}));

const IsolatedSteps = styled('div', () => ({
  minHeight: '150vh',
}));

const FirstIsolatedStep = styled('div', () => ({
  width: '100%',
}));

const IsolatedStep = withStyle<any, { $collapseTopPadding?: boolean }>(
  FirstIsolatedStep,
  ({ $collapseTopPadding = false }) => ({
    paddingTop: $collapseTopPadding ? 0 : '40px',
  })
);

// eslint-disable-next-line react/require-default-props
const OnboardingTimeline = () => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    maybeGoToNext,
    maybeGoToPrevious,
    isUpdatingProfile,
    localStepTalentProfile,
    setLocalStepTalentProfile,
  } = useOnboardingStepContext();
  const {
    education_level,
    job_search_status,
    license_issue_date,
    start_date,
    start_date_option,
    recent_grad,
  } = localStepTalentProfile;
  const { currentStep, intent } = useOnboardingVariantContext();
  const startDateRef = useRef(null);
  const recentGradRef = useRef(null);
  const [errors, setErrors] = useState(null);
  const currentlyLooking = job_search_status !== 'not_looking';
  const recentGradSet = [true, false].includes(recent_grad);

  useEffect(() => {
    if (
      job_search_status &&
      job_search_status !== UNSELECTED &&
      job_search_status !== 'not_looking' &&
      !start_date_option
    ) {
      startDateRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    education_level,
    job_search_status,
    start_date_option,
    start_date,
    recent_grad,
    currentlyLooking,
    recentGradSet,
  ]);
  const isContinueDisabled =
    !job_search_status ||
    job_search_status === UNSELECTED ||
    !start_date_option ||
    (start_date_option === 'specific_date' && !start_date) ||
    // (start_date_option === 'not_sure' && !travelNurseSet) ||
    !recentGradSet;
  const submit = () => {
    if (isContinueDisabled) {
      setErrors('Please answer all questions before continuing.');
      return;
    }

    trackClick('talent.onboarding.submit', {
      Intent: intent,
    });
    maybeGoToNext();
  };
  const displayBackButton = currentStep > 1;
  const startDateOptionsList = currentlyLooking
    ? startDateOptions
    : startDateOptionsNotLooking;

  const recentGradOptions = recentGradWithStudentOptions;
  return (
    <>
      <Helmet>
        <body className="onboarding-page" />
      </Helmet>
      <BodyWrapper>
        <Center>
          <Prompt>
            <OnboardingTitle>
              Great! What’s your ideal timeline?
            </OnboardingTitle>
            {errors && <ErrorMessage>{errors}</ErrorMessage>}
          </Prompt>
          <IsolatedSteps>
            {currentlyLooking && (
              <FirstIsolatedStep>
                <p>How far are you in your search?</p>
                <ButtonSelect
                  options={jobSearchOptions}
                  onChange={(optionValue) => {
                    if (optionValue === 'not_looking') {
                      trackClick('Not looking selected');
                    }
                    setLocalStepTalentProfile({
                      ...localStepTalentProfile,
                      job_search_status: optionValue || UNSELECTED,
                    });
                  }}
                  selectedOptions={job_search_status}
                />
              </FirstIsolatedStep>
            )}
            {((job_search_status && job_search_status !== UNSELECTED) ||
              start_date_option) && (
              <IsolatedStep
                $collapseTopPadding={!currentlyLooking}
                ref={startDateRef}
              >
                <p>{currentlyLooking && 'When are you able to start?'}</p>
                <ButtonSelect
                  options={Array.from(startDateOptionsList)}
                  onChange={(optionValue) => {
                    setLocalStepTalentProfile({
                      ...localStepTalentProfile,
                      start_date_option: optionValue,
                    });
                  }}
                  selectedOptions={
                    Array.from(startDateOptionsList).filter(
                      (option: { value: any }) =>
                        option.value === start_date_option
                    ) as OptionType[]
                  }
                />
                {start_date_option === 'specific_date' && (
                  <DatePickerWrapper>
                    <DatePicker
                      autoFocusCalendar
                      minDate={minDate}
                      maxDate={maxDate}
                      value={start_date}
                      onChange={(obj) => {
                        setLocalStepTalentProfile({
                          ...localStepTalentProfile,
                          start_date: obj.date,
                        });
                      }}
                    />
                  </DatePickerWrapper>
                )}
              </IsolatedStep>
            )}
            {((start_date_option && start_date_option !== 'specific_date') ||
              (start_date_option === 'specific_date' && start_date)) && (
              <IsolatedStep ref={recentGradRef}>
                <p>How long have you been working as an RN?</p>
                <ButtonSelect
                  options={recentGradOptions}
                  onChange={(optionValue) => {
                    if (optionValue === null) {
                      const newLocalStepTalentProfile = {
                        ...localStepTalentProfile,
                      };
                      delete newLocalStepTalentProfile.education_level;
                      delete newLocalStepTalentProfile.recent_grad;
                      setLocalStepTalentProfile(newLocalStepTalentProfile);
                      return;
                    }
                    const localStepTalentProfileUpdate: any = {
                      recent_grad: Boolean(optionValue),
                    };
                    if (optionValue === 'student') {
                      localStepTalentProfileUpdate.education_level = 'student';
                      localStepTalentProfileUpdate.license_issue_date =
                        undefined;
                    }
                    if (optionValue === 'recent_grad_no_license') {
                      localStepTalentProfileUpdate.education_level = 'student';
                      localStepTalentProfileUpdate.license_issue_date = null;
                    }
                    setLocalStepTalentProfile({
                      ...localStepTalentProfile,
                      ...localStepTalentProfileUpdate,
                    });
                  }}
                  selectedOptions={recentGradOptions.filter((option) => {
                    if (education_level) {
                      if (
                        option.value === education_level &&
                        license_issue_date === undefined
                      ) {
                        return true;
                      }
                      if (
                        option.value === 'recent_grad_no_license' &&
                        license_issue_date === null
                      ) {
                        return true;
                      }
                    }
                    return option.value === recent_grad;
                  })}
                />
              </IsolatedStep>
            )}
          </IsolatedSteps>
        </Center>
      </BodyWrapper>

      <FooterWrapper>
        {displayBackButton && (
          <ButtonWrapper>
            <Button
              type="button"
              kind="minimal"
              onClick={maybeGoToPrevious}
              style={{
                padding: '10px 24px',
              }}
            >
              Back
            </Button>
          </ButtonWrapper>
        )}

        {!displayBackButton && <span />}

        <ButtonWrapper>
          <Button
            className="submit"
            onClick={submit}
            disabled={isUpdatingProfile || isContinueDisabled}
            isLoading={isUpdatingProfile}
            style={{
              padding: '10px 24px',
            }}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </FooterWrapper>
    </>
  );
};

export default OnboardingTimeline;
