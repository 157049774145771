import React from 'react';
import PropTypes from 'prop-types';
import { Caption } from 'shared/components/ihcl/input';
import { Textarea as BaseTextarea } from 'baseui/textarea';

type TextareaProps = {
  caption?: string;
  error?: boolean;
  instanceId?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  [key: string]: any;
};

export const Textarea = ({
  caption = null,
  error = false,
  instanceId = null,
  ...props
}: TextareaProps) => (
  <>
    <BaseTextarea
      {...props}
      overrides={{
        Input: {
          props: {
            ...(instanceId && { 'data-instance-id': instanceId }),
          },
        },
      }}
    />
    {caption && <Caption $error={error}>{caption}</Caption>}
  </>
);

Textarea.propTypes = {
  caption: PropTypes.string,
  error: PropTypes.bool,
  instanceId: PropTypes.string,
};
