import React from 'react';
import { styled } from 'shared/components/ihcl/styled';
import { Button } from 'shared/components/ihcl/button';

const DateWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: $theme.sizing.unit4,
}));

const DayOfWeek = styled<'div', { $selected?: boolean }>(
  'div',
  ({ $theme, $selected = false }) => ({
    ...$theme.typography.LabelXSmallBold,
    textTransform: 'uppercase',
    color: $selected ? $theme.colors.grayLine : $theme.colors.grayLabel,
  })
);

const DayOfMonth = styled('div', ({ $theme }) => ({
  ...$theme.typography.HeadingSmall,
  fontWeight: 800,
}));

const Month = styled('div', ({ $theme }) => ({
  ...$theme.typography.LabelXSmall,
}));

const AppointmentDateInput = ({
  date,
  isSelected,
  onSelect,
}: {
  date: Date;
  isSelected: boolean;
  onSelect: Function;
}) => {
  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
  const dayOfMonth = date.toLocaleDateString('en-US', { day: 'numeric' });
  const month = date.toLocaleDateString('en-US', { month: 'short' });

  return (
    <Button
      color="neutral"
      data-testid={`${dayOfWeek}-${dayOfMonth}-${month}`}
      onClick={() => onSelect(date)}
      kind={isSelected ? 'primary' : 'tertiary'}
    >
      <DateWrapper>
        <DayOfWeek>{dayOfWeek}</DayOfWeek>
        <DayOfMonth>{dayOfMonth}</DayOfMonth>
        <Month>{month}</Month>
      </DateWrapper>
    </Button>
  );
};

const FullWidth = styled('div', () => ({
  width: '100%',
}));

const FourColumnGrid = styled('div', ({ $theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: $theme.sizing.unit4,
}));

export const AppointmentDates = ({
  times,
  date,
  setDate,
}: {
  times: Array<Date>;
  date: Date;
  setDate: Function;
}) => {
  const [dates, setDates] = React.useState<Array<Date>>([]);

  React.useEffect(() => {
    const screenerDates =
      times
        .map((availableTime) => new Date(availableTime.toDateString()))
        ?.reduce((agg, availableDay) => {
          const match = agg.find((d) => d.getTime() === availableDay.getTime());
          if (!match) {
            agg.push(availableDay);
          }

          return agg;
        }, []) || [];
    setDates(screenerDates);
    setDate(screenerDates.length > 0 ? screenerDates[0] : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times]);

  return (
    <FullWidth>
      <FourColumnGrid>
        {dates.map((d) => (
          <AppointmentDateInput
            key={`date-${d.getTime()}`}
            date={d}
            isSelected={d.getTime() === date?.getTime()}
            onSelect={setDate}
          />
        ))}
      </FourColumnGrid>
    </FullWidth>
  );
};
