import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, withStyle } from 'shared/components/ihcl/styled';
import {
  jobSearchOptions,
  noYesOptions,
  recentGradOptions as recentGradNoStudentOptions,
  recentGradWithStudentOptions,
  startDateOptions,
  startDateOptionsNotLooking,
} from 'shared/constants/selectOptions';

import { trackClick } from 'shared/helpers/tracking';

import {
  ButtonSelect,
  UNSELECTED,
  OptionType,
} from 'shared/components/ihcl/button_select';
import { DatePicker } from 'shared/components/ihcl/datepicker';
import { Center } from 'shared/components/ihcl/positioning';

import {
  Onboarding,
  OnboardingSubtitle,
  OnboardingTitle,
  OnboardingContext,
} from 'registration/containers/Onboarding';
import OnboardingTestimonial from './OnboardingTestimonial';

const minDate = new Date();
const maxDate = new Date(
  minDate.getFullYear() + 2,
  minDate.getMonth(),
  minDate.getDate()
);

const DatePickerWrapper = styled('div', () => ({
  marginTop: '1em',
  maxWidth: '250px',
}));

const IsolatedSteps = styled('div', () => ({
  minHeight: '150vh',
}));

const FirstIsolatedStep = styled('div', () => ({
  width: '100%',
}));

const IsolatedStep = withStyle<any, { $collapseTopPadding?: boolean }>(
  FirstIsolatedStep,
  ({ $collapseTopPadding = false }) => ({
    paddingTop: $collapseTopPadding ? 0 : '40px',
  })
);

export const testimonial = (
  <OnboardingTestimonial
    quote="This is the fastest job searching experience I have ever had. Very helpful and knowledgeable."
    attributionLine="#humbleBrag from:"
    author={
      <>
        Anasuya Bhattacharyaa, PACU RN
        <br />
        Hired at Baylor Scott and White
      </>
    }
  />
);

// eslint-disable-next-line react/require-default-props
const OnboardingTimeline = ({
  autoProgress = false,
  nextAction = null,
  nextActionForRecentGrads = null,
  previousAction,
  stepNumber,
  useNewStudentOption = false,
}) => {
  const {
    history,
    onboardingData,
    profileType = 'nurse',
    setOnboardingData,
  } = useContext(OnboardingContext);
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    education_level,
    job_search_status,
    license_issue_date,
    start_date,
    start_date_option,
    travel_nurse_preferred,
    recent_grad,
  } = onboardingData;
  const startDateRef = useRef(null);
  const recentGradRef = useRef(null);
  const travelNurseRef = useRef(null);
  const [isJobSearchStatusFirstQuestion, setIsJobSearchStatusFirstQuestion] =
    useState(false);
  const recentGradSet = [true, false].includes(recent_grad);
  const travelNurseSet = [true, false].includes(travel_nurse_preferred);

  const urlParams = new URL(window.location.href).searchParams;
  const jobSearchStatus =
    job_search_status || urlParams.get('job_search_status');
  const currentlyLooking = jobSearchStatus !== 'not_looking';

  useEffect(() => {
    if (!jobSearchStatus) {
      history.push(previousAction);
    }
  }, [history, jobSearchStatus, previousAction]);

  useEffect(() => {
    if (
      jobSearchStatus &&
      jobSearchStatus !== UNSELECTED &&
      jobSearchStatus !== 'not_looking' &&
      !start_date_option
    ) {
      startDateRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    if (
      jobSearchStatus &&
      jobSearchStatus !== UNSELECTED &&
      ((start_date_option && start_date_option !== 'specific_date') ||
        (start_date_option === 'specific_date' && start_date)) &&
      !recentGradSet
    ) {
      recentGradRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    if (
      !currentlyLooking &&
      recentGradSet &&
      start_date_option === 'not_sure'
    ) {
      travelNurseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    education_level,
    job_search_status,
    start_date_option,
    start_date,
    recent_grad,
    currentlyLooking,
    recentGradSet,
  ]);
  const startDateOptionsList = currentlyLooking
    ? startDateOptions
    : startDateOptionsNotLooking;
  let back = false;
  if (history.location.state && history.location.state.back) {
    ({ back } = history.location.state);
  }
  const stepKeys = [
    'job_search_status',
    'start_date',
    'start_date_option',
    'travel_nurse_preferred',
    'recent_grad',
    'education_level',
    'license_issue_date',
  ];

  const recentGradOptions = useNewStudentOption
    ? recentGradWithStudentOptions
    : recentGradNoStudentOptions;

  return (
    <Center>
      <Onboarding
        autoProgress={autoProgress && !back}
        centerStepContents
        isNextDisabled={
          !jobSearchStatus ||
          jobSearchStatus === UNSELECTED ||
          !start_date_option ||
          (start_date_option === 'specific_date' && !start_date) ||
          (start_date_option === 'not_sure' && !travelNurseSet) ||
          !recentGradSet
        }
        currentStep={stepNumber}
        nextAction={
          recent_grad && nextActionForRecentGrads
            ? nextActionForRecentGrads
            : nextAction
        }
        previousAction={previousAction}
        stepKeys={stepKeys}
      >
        <OnboardingTitle>
          {!currentlyLooking &&
            'No problem! When will you want to look for a permanent job?'}
          {currentlyLooking && 'Great! What’s your ideal timeline?'}
        </OnboardingTitle>
        <IsolatedSteps>
          {currentlyLooking && (
            <FirstIsolatedStep>
              <OnboardingSubtitle>
                How far are you in your search?
              </OnboardingSubtitle>
              <ButtonSelect
                options={jobSearchOptions}
                onChange={(optionValue) => {
                  if (optionValue === 'not_looking') {
                    trackClick('Not looking selected');
                  }
                  setOnboardingData({
                    ...onboardingData,
                    job_search_status: optionValue || UNSELECTED,
                  });
                  if (!isJobSearchStatusFirstQuestion) {
                    setIsJobSearchStatusFirstQuestion(true);
                  }
                }}
                selectedOptions={jobSearchStatus}
              />
              {(!jobSearchStatus || jobSearchStatus === UNSELECTED) &&
                testimonial}
            </FirstIsolatedStep>
          )}
          {(!currentlyLooking ||
            (jobSearchStatus && jobSearchStatus !== UNSELECTED) ||
            start_date_option) && (
            <IsolatedStep
              $collapseTopPadding={!currentlyLooking}
              ref={startDateRef}
            >
              <OnboardingSubtitle>
                {currentlyLooking && 'When are you able to start?'}
              </OnboardingSubtitle>
              <ButtonSelect
                options={Array.from(startDateOptionsList)}
                onChange={(optionValue) => {
                  setOnboardingData({
                    ...onboardingData,
                    start_date_option: optionValue,
                  });
                }}
                selectedOptions={
                  Array.from(startDateOptionsList).filter(
                    (option: { value: any }) =>
                      option.value === start_date_option
                  ) as OptionType[]
                }
              />
              {start_date_option === 'specific_date' && (
                <DatePickerWrapper>
                  <DatePicker
                    autoFocusCalendar
                    minDate={minDate}
                    maxDate={maxDate}
                    value={start_date}
                    onChange={(obj) => {
                      setOnboardingData({
                        ...onboardingData,
                        start_date: obj.date,
                      });
                    }}
                  />
                </DatePickerWrapper>
              )}
              {!start_date_option &&
                !isJobSearchStatusFirstQuestion &&
                testimonial}
            </IsolatedStep>
          )}
          {((start_date_option && start_date_option !== 'specific_date') ||
            (start_date_option === 'specific_date' && start_date)) && (
            <IsolatedStep ref={recentGradRef}>
              <OnboardingSubtitle>
                How long have you been working as{' '}
                {profileType === 'tech' ? 'a tech' : 'an RN'}?
              </OnboardingSubtitle>
              <ButtonSelect
                options={recentGradOptions}
                onChange={(optionValue) => {
                  if (optionValue === null) {
                    const newOnboardingData = { ...onboardingData };
                    delete newOnboardingData.education_level;
                    delete newOnboardingData.recent_grad;
                    setOnboardingData(newOnboardingData);
                    return;
                  }
                  const onboardingDataUpdate = {
                    recent_grad: Boolean(optionValue),
                  } as any; // TODO: Fix typing
                  if (optionValue === 'student') {
                    onboardingDataUpdate.education_level = 'student';
                    onboardingDataUpdate.license_issue_date = undefined;
                  }
                  if (optionValue === 'recent_grad_no_license') {
                    onboardingDataUpdate.education_level = 'student';
                    onboardingDataUpdate.license_issue_date = null;
                  }
                  setOnboardingData({
                    ...onboardingData,
                    ...onboardingDataUpdate,
                  });
                }}
                selectedOptions={recentGradOptions.filter((option) => {
                  if (education_level) {
                    if (
                      option.value === education_level &&
                      license_issue_date === undefined
                    ) {
                      return true;
                    }
                    if (
                      option.value === 'recent_grad_no_license' &&
                      license_issue_date === null
                    ) {
                      return true;
                    }
                  }
                  return option.value === recent_grad;
                })}
              />
            </IsolatedStep>
          )}
          {!currentlyLooking &&
            recentGradSet &&
            start_date_option === 'not_sure' && (
              <IsolatedStep ref={travelNurseRef}>
                <OnboardingSubtitle>
                  Are you looking for a Travel{' '}
                  {profileType === 'tech' ? ' ' : 'Nurse '}position?
                </OnboardingSubtitle>
                <ButtonSelect
                  options={noYesOptions}
                  orientation="horizontal"
                  onChange={(optionValue) => {
                    setOnboardingData({
                      ...onboardingData,
                      travel_nurse_preferred: optionValue,
                    });
                  }}
                  selectedOptions={noYesOptions.filter(
                    (option) => option.value === travel_nurse_preferred
                  )}
                />
              </IsolatedStep>
            )}
        </IsolatedSteps>
      </Onboarding>
    </Center>
  );
};
OnboardingTimeline.propTypes = {
  autoProgress: PropTypes.bool,
  nextAction: PropTypes.string,
  nextActionForRecentGrads: PropTypes.string,
  previousAction: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  useNewStudentOption: PropTypes.bool,
};

export default OnboardingTimeline;
