import PropTypes from 'prop-types';
import React from 'react';
import { styled } from 'shared/components/ihcl/styled';
import { Helmet } from 'react-helmet';

import { Logo } from 'shared/components/ihcl/icon';
import { WrappedLucideIcon } from 'shared/components/ihcl/lucideIcon';
import { isNative } from 'shared/helpers/native';
import withGoogleOptimize from 'shared/helpers/withGoogleOptimize';

const SignUpWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  maxHeight: '1000px',
  maxWidth: '768px',
  margin: '0 auto',
  paddingTop: '20px',
  paddingBottom: '40px',
  width: '100%',
  paddingLeft: '24px',
  paddingRight: '24px',
  textAlign: 'center',
});

const HeaderWrapper = styled('div', {
  textAlign: 'left',
});

const NonHiringContainer = ({ bodyClasses = '', children }) => (
  <div className="non-hiring">
    <Helmet>
      <body className={`${bodyClasses} registration-app`} />
    </Helmet>
    <SignUpWrapper>
      <HeaderWrapper>
        <div className="container-fluid brand-logo">
          <Logo />
        </div>
        {isNative() && (
          <a href="/" className="back-btn">
            <WrappedLucideIcon name="ArrowLeft" aria-hidden="true" /> Back
          </a>
        )}
      </HeaderWrapper>
      {children}
    </SignUpWrapper>
  </div>
);

NonHiringContainer.propTypes = {
  bodyClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default withGoogleOptimize(NonHiringContainer);
