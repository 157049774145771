import React from 'react';
import { OtherLocationsTout } from 'registration/components/new_pages/OnboardingCurrentLocation/OtherLocationsTout';
import getSalaryText from 'registration/helpers/getSalaryText';
import normalizeMarketName from 'registration/helpers/normalizeMarketName';
import { Button } from 'shared/components/ihcl/button';
import { ButtonSelect } from 'shared/components/ihcl/button_select';
import { styled } from 'shared/components/ihcl/styled';

const ButtonWrapper = styled('div', {
  marginTop: '32px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

type PediatricLocationsProps = {
  openToRelocation?: boolean;
  otherDesiredLocations: any[];
  pediatricLocations: any[];
  setOpenToRelocation: Function;
  setOtherDesiredLocations: Function;
};
export const PediatricLocations = ({
  pediatricLocations,
  openToRelocation = undefined,
  setOpenToRelocation,
  otherDesiredLocations,
  setOtherDesiredLocations,
}: PediatricLocationsProps) => {
  const shownLocations = pediatricLocations
    .slice(0, 3)
    .map((location) => ({ ...location, value: location.market }));
  const getSelectedOptions = (selectedOptions) =>
    shownLocations.filter((selectedOption) =>
      selectedOptions.some((option) => option.market === selectedOption.market)
    );
  return (
    <>
      {shownLocations.length > 0 ? (
        <>
          <span style={{ fontWeight: 900 }}>
            Popular areas for Pediatric Nurses
          </span>
          <p>Tap to add areas you are interested in.</p>
          <ButtonSelect
            buttonProps={{
              style: {
                borderRadius: '52px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '8px',
              },
            }}
            fillWidth
            multiSelect
            onChange={(val, option, remove) => {
              if (remove) {
                setOtherDesiredLocations([
                  ...otherDesiredLocations.filter(
                    (selectedLocation) =>
                      selectedLocation.value !== option.value
                  ),
                ]);
              } else {
                setOtherDesiredLocations([...otherDesiredLocations, option]);
              }
            }}
            selectedOptions={getSelectedOptions(otherDesiredLocations)}
            options={shownLocations.map((location) => ({
              ...location,
              label: (
                <>
                  <strong>{normalizeMarketName(location.market)}</strong>
                  {getSalaryText(location.maxSalary)}
                </>
              ),
              value: location.market,
              tagHidden: true,
            }))}
          />
          <ButtonWrapper>
            <Button
              onClick={() => setOpenToRelocation(true)}
              kind={openToRelocation === true ? 'primary' : 'tertiary'}
            >
              Show me more locations
            </Button>
            <Button
              onClick={() => setOpenToRelocation(false)}
              kind={openToRelocation === false ? 'primary' : 'minimal'}
            >
              I&apos;m not interested in other locations
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <>
          <OtherLocationsTout />
          <Button
            onClick={() => setOpenToRelocation(false)}
            kind={openToRelocation === false ? 'primary' : 'tertiary'}
            style={{
              minWidth: '100px',
              marginRight: '32px',
            }}
          >
            No
          </Button>
          <Button
            onClick={() => setOpenToRelocation(true)}
            kind={openToRelocation === true ? 'primary' : 'tertiary'}
            style={{
              minWidth: '100px',
            }}
          >
            Yes
          </Button>
        </>
      )}
    </>
  );
};
