import { Redirect, Route, Switch, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';
import {
  saveUnbounceParamToLocalforage,
  UnbounceParam,
} from 'registration/helpers/experiment/xena';
import { clearErrorDialog } from 'shared/actions/errorActions';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import ErrorDialog from 'shared/components/ErrorDialog';
import activateABTest, {
  getRegVariantChar,
} from 'shared/helpers/activateABTest';
import { parsePageQuery } from 'shared/helpers/query';
import withGATracker from 'shared/helpers/withGATracker';

import getGoogleClickID from '../helpers/getGoogleClickID';
import { getPreviewTrackingParams } from '../helpers/getPreviewTrackingParams';
import TechOnboardContainerA from './TechOnboardContainerA';

class TechOnboardContainer extends React.Component {
  constructor(props) {
    super(props);

    // TODO: Revisit intent, variant, etc?
    if (props?.techProfile?.signup_intent === 'hiring') {
      const { onboard_variant: onboardVariant } = props.techProfile;
      // Force the `onboardVariant` if it exists, assign one if it's null
      this.regVariant = activateABTest(
        'regTechVariant',
        onboardVariant,
        'techOnboarding'
      );
    } else {
      this.regVariant = activateABTest('regTechVariant', 'a', 'techOnboarding');
    }

    this.closeErrorDialog = this.closeErrorDialog.bind(this);
  }

  async componentDidMount() {
    const query = parsePageQuery(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    const gclid = getGoogleClickID(query);
    if (gclid) {
      Cookie.set('gclid', gclid, 90);
    }

    const mpOptions = getPreviewTrackingParams(
      query.preview_checked,
      query.preview_enrolled
    );

    const mpVariant = query.mp_variant;
    if (mpVariant) {
      mpOptions.variant = mpVariant;
    }
    mpOptions['Onboard Variant'] = this.regVariant;
    mixpanel.track('Sign Up Visited', mpOptions);

    await saveUnbounceParamToLocalforage(UnbounceParam.desired_location, query);
  }

  closeErrorDialog() {
    this.props.dispatch(clearErrorDialog());
  }

  render() {
    const { location, match } = this.props;
    const funnelMatch = matchPath(window.location.pathname, {
      path: `${match.url}/:funnelChar`,
    });
    const funnelChar = funnelMatch ? funnelMatch.params.funnelChar : '';
    const regVariantChar = getRegVariantChar(this.regVariant);

    if (regVariantChar !== funnelChar) {
      if (funnelChar === '_') {
        // From the disambiguation page, we want to correctly set the variant
        // but skip the first step.
        const disambiguationFunnelMatch = matchPath(window.location.pathname, {
          path: `${match.url}/_/:stepNumber`,
        });
        const stepNumber = disambiguationFunnelMatch
          ? disambiguationFunnelMatch.params.stepNumber
          : '';
        return (
          // Preserve the query string during the redirect
          <Redirect
            to={{
              ...location,
              pathname: `${match.url}/${regVariantChar}/${stepNumber}`,
            }}
          />
        );
      }
      return (
        // Preserve the query string during the redirect
        <Redirect
          to={{ ...location, pathname: `${match.url}/${regVariantChar}` }}
        />
      );
    }

    let errorDialog = null;
    const errorDialogMsg = this.props.uiState.get('errorDialogMessage');
    if (errorDialogMsg) {
      errorDialog = (
        <ErrorDialog message={errorDialogMsg} onClose={this.closeErrorDialog} />
      );
    }

    return (
      <ErrorBoundary>
        <div>
          <Switch>
            <Route component={TechOnboardContainerA} path={`${match.url}/a`} />
          </Switch>
          {errorDialog}
        </div>
      </ErrorBoundary>
    );
  }
}

TechOnboardContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  techProfile: PropTypes.object,
  uiState: PropTypes.object.isRequired,
};

TechOnboardContainer.defaultProps = {
  techProfile: {},
};

const mapStateToProps = (state) => ({
  talentProfile: state.talent_profile,
  techProfile: state.tech_profile,
  uiState: state.uiState,
});

export default withGATracker(connect(mapStateToProps)(TechOnboardContainer));
