import Cookie from 'js-cookie';
import React, { useEffect } from 'react';
import {
  saveUnbounceParamToLocalforage,
  UnbounceParam,
} from 'registration/helpers/experiment/xena';
import { parsePageQuery } from 'shared/helpers/query';
import withGATracker from 'shared/helpers/withGATracker';

import getGoogleClickID from '../helpers/getGoogleClickID';
import OnboardDisambiguationStep from '../components/new_pages/OnboardDisambiguationStep';

type OnboardDisambiguationContainerProps = {
  location: any;
};

const OnboardDisambiguationContainer = ({
  location,
}: OnboardDisambiguationContainerProps) => {
  useEffect(() => {
    const query = parsePageQuery(location.search);

    const gclid = getGoogleClickID(query);
    if (gclid) {
      Cookie.set('gclid', gclid, 90);
    }
    window.mixpanel.track('Sign Up Disambiguation Visited');

    saveUnbounceParamToLocalforage(UnbounceParam.desired_location, query);
  }, []);

  return <OnboardDisambiguationStep />;
};

export default withGATracker(OnboardDisambiguationContainer);
