import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getStateOptions, getTalentProfile } from 'registration/selectors';
import { getNurseSpecialtyOptions } from 'shared/selectors';

import GetHired from './pages/GetHired';
import OnboardingFormEmail from './pages/OnboardingFormEmailCombined';
import OnboardingPassword from './pages/OnboardingFormPassword';
import OnboardingLicenses from './pages/OnboardingLicenses';
import OnboardingPermanentJob from './pages/OnboardingPermanentJob';
import OnboardingSpecialties from './pages/OnboardingSpecialties';
import OnboardingTalentDetailsForm from './pages/OnboardingTalentDetailsForm';
import OnboardingTimeline from './pages/OnboardingTimeline';
import RedirectPage from './pages/RedirectPage';
import OnboardingStepController, {
  COMPLETE_ONBOARDING_STEP,
  GET_HIRED_STEP,
  PASSWORD_STEP,
  SIGNUP_STEP,
} from './OnboardingStepController';

import OnboardingStep from './OnboardingStep';
import StepProgress from './StepProgress';

const SPECIALTIES_STEP = 'specialties';
const LICENSES_STEP = 'licenses';
const PERMANENT_WORK_STEP = 'permanentWork';
const TIMELINE_STEP = 'timeline';

const ONBOARD_VARIANT = 'gamora';
const SIGN_UP_STEP_LIST = [
  SIGNUP_STEP,
  PASSWORD_STEP,
  'detailsForm',
  GET_HIRED_STEP,
  COMPLETE_ONBOARDING_STEP,
];

const RESUME_SIGN_UP_STEP_LIST = [
  SIGNUP_STEP,
  PASSWORD_STEP,
  'detailsForm',
  SPECIALTIES_STEP,
  LICENSES_STEP,
  PERMANENT_WORK_STEP,
  TIMELINE_STEP,
  COMPLETE_ONBOARDING_STEP,
];

const ONBOARD_STEP_LIST = [
  'detailsForm',
  GET_HIRED_STEP,
  COMPLETE_ONBOARDING_STEP,
];

const OnboardingForm = ({
  nurseSpecialtyOptions,
  serverTalentProfile = {},
  stateOptions,
}) => {
  const { intent, type } = useParams();
  const getStepComponent = (step) => {
    let stepComponent;
    switch (step) {
      case 'detailsForm': {
        const keys = ['fullName', 'currentLocation'];
        if (intent !== 'resume') {
          keys.push('educationLevel');
        }
        if (['education', 'resume'].includes(intent)) {
          keys.push('phone');
        }
        if (intent === 'resume') {
          keys.push('other_locations_attributes');
        }
        stepComponent = (
          <OnboardingStep key="detailsForm" stepName="detailsForm">
            <StepProgress />
            <OnboardingTalentDetailsForm
              pageTalentProfileKeys={keys}
              includeSignInLink={intent !== 'resume'}
            />
          </OnboardingStep>
        );
        break;
      }
      case SIGNUP_STEP:
        stepComponent = (
          <OnboardingStep key={SIGNUP_STEP} stepName={SIGNUP_STEP}>
            <StepProgress />
            <OnboardingFormEmail includeGoogleSignIn />
          </OnboardingStep>
        );
        break;
      case PASSWORD_STEP: {
        stepComponent = (
          <OnboardingStep key={PASSWORD_STEP} stepName={PASSWORD_STEP}>
            <StepProgress />
            <OnboardingPassword />
          </OnboardingStep>
        );
        break;
      }
      case GET_HIRED_STEP:
        stepComponent = (
          <OnboardingStep
            key={GET_HIRED_STEP}
            stepName={GET_HIRED_STEP}
            bodyClasses="onboarding-page get-hired-page"
          >
            <GetHired intent={intent} />
          </OnboardingStep>
        );
        break;
      case COMPLETE_ONBOARDING_STEP:
        stepComponent = <RedirectPage intent={intent} />;
        break;
      case SPECIALTIES_STEP:
        stepComponent = (
          <OnboardingStep key={SPECIALTIES_STEP} stepName={SPECIALTIES_STEP}>
            <StepProgress />
            <OnboardingSpecialties
              nurseSpecialtyOptions={nurseSpecialtyOptions}
              pageTalentProfileKeys={['nurseSpecialties']}
            />
          </OnboardingStep>
        );
        break;
      case LICENSES_STEP:
        stepComponent = (
          <OnboardingStep
            key={LICENSES_STEP}
            justifyFlexStart
            stepName={LICENSES_STEP}
          >
            <StepProgress />
            <OnboardingLicenses
              currentState={serverTalentProfile.current_state}
              pageTalentProfileKeys={[
                'stateLicenses',
                'license_being_processed',
              ]}
              stateOptions={stateOptions}
            />
          </OnboardingStep>
        );
        break;
      case PERMANENT_WORK_STEP: {
        stepComponent = (
          <OnboardingStep
            key={PERMANENT_WORK_STEP}
            justifyFlexStart
            stepName={PERMANENT_WORK_STEP}
          >
            <StepProgress />
            <OnboardingPermanentJob
              pageTalentProfileKeys={['hiring_activated']}
            />
          </OnboardingStep>
        );
        break;
      }
      case TIMELINE_STEP: {
        if (serverTalentProfile.hiring_activated_at) {
          const keys = [
            'job_search_status',
            'start_date',
            'start_date_option',
            'travel_nurse_preferred',
            'recent_grad',
            'education_level',
            'license_issue_date',
          ];
          stepComponent = (
            <OnboardingStep
              key={TIMELINE_STEP}
              pageTalentProfileKeys={keys}
              stepName={TIMELINE_STEP}
            >
              <StepProgress />
              <OnboardingTimeline />
            </OnboardingStep>
          );
        } else {
          stepComponent = <RedirectPage intent={intent} />;
        }

        break;
      }
      default:
        return null;
    }

    return stepComponent;
  };

  let defaultStepList =
    serverTalentProfile.id && type === 'onboard'
      ? ONBOARD_STEP_LIST
      : SIGN_UP_STEP_LIST;
  if (intent === 'resume') {
    defaultStepList = RESUME_SIGN_UP_STEP_LIST;
  }
  return (
    <OnboardingStepController
      defaultStepList={defaultStepList}
      getStepComponent={getStepComponent}
      intent={intent}
      variant={ONBOARD_VARIANT}
    />
  );
};

OnboardingForm.propTypes = {
  nurseSpecialtyOptions: PropTypes.object.isRequired,
  serverTalentProfile: PropTypes.object,
  stateOptions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  nurseSpecialtyOptions: getNurseSpecialtyOptions(state),
  serverTalentProfile: getTalentProfile(state),
  stateOptions: getStateOptions(state),
});

export default connect(mapStateToProps)(OnboardingForm);
