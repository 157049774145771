import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { parsePageQuery } from 'shared/helpers/query';
import { usePrevious } from 'shared/helpers/hooks';
import { trackExperimentAssignment } from 'shared/helpers/tracking';
import { getExperimentAssignmentWithoutTracking } from 'shared/selectors';
import { getTalentProfile } from '../selectors';
import ScheduleScreening from './ScheduleScreening';
import OnboardingCareSettings from '../components/new_pages/OnboardingCareSettings';
import OnboardingCredentialsIsolatedQuestions from '../components/new_pages/OnboardingCredentialsIsolatedQuestions';
import OnboardingCurrentLocation from '../components/new_pages/OnboardingCurrentLocation';
import OnboardingEducation from '../components/new_pages/OnboardingEducation';
import OnboardingEmail from '../components/new_pages/OnboardingEmailDelight';
import OnboardingLicenses from '../components/new_pages/OnboardingLicenses';
import OnboardingLocation from '../components/new_pages/OnboardingLocation';
import OnboardingLocationMotivation from '../components/new_pages/OnboardingLocationMotivation';
import OnboardingLocationPersonalized from '../components/new_pages/OnboardingLocationPersonalized';
import OnboardingName from '../components/new_pages/OnboardingName';
import OnboardingPassword from '../components/new_pages/OnboardingPassword';
import OnboardingPermanentJob from '../components/new_pages/OnboardingPermanentJob';
import OnboardingPhoneSimple from '../components/new_pages/OnboardingPhoneSimple';
import OnboardingRNLicenseDate from '../components/new_pages/OnboardingRNLicenseDate';
import OnboardingResume from '../components/new_pages/OnboardingResume';
import OnboardingSpecialtiesList from '../components/new_pages/OnboardingSpecialtiesList';
import OnboardingTimeline from '../components/new_pages/OnboardingTimelineIsolatedQuestions';
import {
  augmentOptions,
  getAugmentedOnboardingData,
} from '../helpers/talentProfileConversions';
import { OnboardingContext } from './Onboarding';

const PASSWORD_STEP = 12;
const liteProfileSkippedSteps = [10, 11, PASSWORD_STEP];

const validStepNames = ['learn', 'schedule'];

export const setUrl = (currentOnboardStep, history, path, locPathname) => {
  if (locPathname) {
    // Don't set the step if we're on a location with a valid step name
    const pathArr = locPathname.split(`${path}/`);
    if (pathArr[1] && validStepNames.includes(pathArr[1])) {
      return;
    }
  }

  if (
    currentOnboardStep &&
    (currentOnboardStep >= 1 || validStepNames.includes(currentOnboardStep))
  ) {
    history.push(`${path}/${currentOnboardStep}${window.location.search}`);
  } else {
    history.push(`${path}/1`);
  }
};

const TalentOnboardContainerQuicksilver = ({
  match,
  talentProfile,
  getConnectableExperiment,
}) => {
  const [onboardingData, setOnboardingData] = useState({});
  const [onboardingUpdates, setOnboardingUpdates] = useState({});
  const [isRegistrationDataParsed, setIsRegistrationDataParsed] =
    useState(false);
  const [onboardingOptions, setOnboardingOptions] = useState({});
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [queryParams, setQueryParams] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [skippedSteps, setSkippedSteps] = useState(liteProfileSkippedSteps);
  const priorStep = usePrevious(currentStep);
  const node = document.getElementById('registration-app');
  const { ...options } = JSON.parse(node.getAttribute('data'));
  const isLiteProfileActivating = Boolean(
    talentProfile.id &&
      (!talentProfile.hiring_activated_at ||
        talentProfile.signup_intent === 'resume')
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setQueryParams(parsePageQuery());
    document.documentElement.classList.add('onboarding-ironman');
    // This fixes some odd scroll behavior on iOS when the onscreen keyboard is
    // hidden.
    document.addEventListener('focusout', () => {
      if (document.documentElement.scrollTop > 0) {
        document.documentElement.scrollTop = 0;
      }
    });
    setOnboardingOptions(augmentOptions(options, Object.keys(options)));
    if (!talentProfile.user_id) {
      if (typeof localforage !== 'undefined') {
        localforage.getItem(
          'onboardingUpdates',
          (err, localOnboardingUpdates) => {
            let currentOnboardStep =
              localOnboardingUpdates?.current_onboard_step;
            // Allow skipping the first step when coming from the disambiguation page
            if (
              window.location.pathname.replace(`${match.path}/`, '') === '2'
            ) {
              currentOnboardStep = 2;
              setCurrentStep(2);
            }
            setUrl(
              currentOnboardStep || 1,
              history,
              match.path,
              location?.pathname
            );
            setOnboardingUpdates({
              ...localOnboardingUpdates,
              ...onboardingUpdates,
            });
            setOnboardingData(
              getAugmentedOnboardingData(onboardingData, talentProfile, options)
            );
            setIsRegistrationDataParsed(true);
          }
        );
      } else {
        setOnboardingData(
          getAugmentedOnboardingData(onboardingData, talentProfile, options)
        );
        setUrl(1, history, match.path, location?.pathname);
        setIsRegistrationDataParsed(true);
      }
    } else {
      if (talentProfile.signup_intent === 'resume') {
        liteProfileSkippedSteps.push(6, 13, 14);
        setSkippedSteps(liteProfileSkippedSteps);
      }
      setOnboardingData(
        getAugmentedOnboardingData(onboardingData, talentProfile, options)
      );
      setUrl(
        talentProfile.current_onboard_step || 1,
        history,
        match.path,
        location?.pathname
      );
      setIsRegistrationDataParsed(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Object.keys(onboardingData).length) {
      return;
    }
    const step = location.pathname.replace(`${match.path}/`, '');
    if (validStepNames.includes(step)) {
      return;
    }
    let stepNumber = Number(step);
    if (Number.isNaN(stepNumber) || Number(stepNumber) === 0) {
      stepNumber = 1;
    }
    if (isLiteProfileActivating) {
      if (stepNumber === 1) {
        stepNumber = 2;
        setUrl(stepNumber, history, match.path, location?.pathname);
      } else if (liteProfileSkippedSteps.includes(stepNumber)) {
        if (stepNumber > currentStep) {
          stepNumber = Math.max(...liteProfileSkippedSteps) + 1;
        } else {
          stepNumber = Math.min(...liteProfileSkippedSteps) - 1;
        }
        setUrl(stepNumber, history, match.path, location?.pathname);
      }
    }

    if (
      !isLiteProfileActivating &&
      onboardingData &&
      onboardingData.user_id &&
      onboardingData.signup_intent !== 'resume' &&
      stepNumber < PASSWORD_STEP
    ) {
      stepNumber = PASSWORD_STEP;
    }
    if (stepNumber) {
      if (currentStep !== stepNumber) {
        setCurrentStep(stepNumber);
      }
    }
  }, [
    currentStep,
    history,
    location?.pathname,
    match.path,
    onboardingData,
    isLiteProfileActivating,
  ]);

  const [screenSchedulePath, setScreenSchedulePath] = useState(
    `${match.path}/schedule`
  );
  const [finalAction, setFinalAction] = useState(screenSchedulePath);

  useEffect(() => {
    const newScreenSchedulePath = `${match.path}/schedule`;
    if (screenSchedulePath !== newScreenSchedulePath) {
      setScreenSchedulePath(newScreenSchedulePath);
    }
  }, [match.path, screenSchedulePath]);

  useEffect(() => {
    if (
      !onboardingData.connectable ||
      onboardingData.screen_status === 'failed' ||
      (queryParams && queryParams.forceFinal === 'home') ||
      onboardingData.auto_scheduling
    ) {
      setFinalAction('/talent/home');
    } else {
      setFinalAction(screenSchedulePath);
    }
  }, [
    onboardingData.screen_status,
    onboardingData.auto_scheduling,
    onboardingData.connectable,
    queryParams,
    screenSchedulePath,
  ]);

  useEffect(() => {
    // We want to ignore recent grads for this experiment tracking
    // They are always non-connectable
    if (!onboardingData?.id || onboardingData?.recent_grad) return;

    const exp = getConnectableExperiment(
      onboardingData.feature_flags,
      onboardingData.id
    );
    trackExperimentAssignment('web6207_connectable_talent', exp, {
      context: 'onboarding',
      $user_id: onboardingData.user_id,
    });
  }, [
    onboardingData.feature_flags,
    onboardingData.id,
    onboardingData.recent_grad,
  ]);

  if (!isRegistrationDataParsed) {
    return null;
  }

  return (
    <OnboardingContext.Provider
      value={{
        history,
        isLiteProfileActivating,
        isUpdatingProfile,
        onboardingBasePath: match.path,
        onboardingData,
        onboardingOptions,
        onboardingUpdates,
        priorStep,
        setIsUpdatingProfile,
        setOnboardingData,
        setOnboardingUpdates,
        skippedSteps,
        totalSteps: 14,
      }}
    >
      <BrowserRouter>
        <Switch>
          <Redirect
            exact
            from={match.url}
            to={{ ...location, pathname: `${match.path}/${currentStep}` }}
          />
          <Route
            path={`${match.path}/:stepNumber`}
            render={(props) => {
              const step = location.pathname.replace(`${match.path}/`, '');
              if (validStepNames.includes(step)) {
                switch (step) {
                  case 'learn':
                    return (
                      <OnboardingEducation
                        {...props}
                        nextAction={`${match.path}/1`}
                      />
                    );
                  case 'schedule':
                    return (
                      <ScheduleScreening {...props} nextAction="/talent/home" />
                    );
                  default: {
                    return null;
                  }
                }
              }

              switch (currentStep) {
                case 1:
                  return (
                    <OnboardingPermanentJob
                      {...props}
                      stepNumber={currentStep}
                      nextAction={`${match.path}/2`}
                    />
                  );
                case 2:
                  return (
                    <OnboardingTimeline
                      {...props}
                      useNewStudentOption
                      stepNumber={currentStep}
                      autoProgress
                      previousAction={
                        isLiteProfileActivating ? '#' : `${match.path}/1`
                      }
                    />
                  );
                case 3:
                  return onboardingUpdates.recent_grad ? (
                    <OnboardingRNLicenseDate
                      {...props}
                      stepNumber={currentStep}
                      nextActionForCurrentStudents={`${match.path}/5`}
                    />
                  ) : (
                    <OnboardingCareSettings
                      {...props}
                      stepNumber={currentStep}
                    />
                  );
                case 4:
                  return (
                    <OnboardingCredentialsIsolatedQuestions
                      {...props}
                      useNoStudentEducationOptions
                      stepNumber={currentStep}
                    />
                  );
                case 5:
                  return (
                    <OnboardingSpecialtiesList
                      {...props}
                      hideRecentGradYearEntry
                      stepNumber={currentStep}
                      previousAction={
                        onboardingUpdates.education_level === 'student'
                          ? `${match.path}/3`
                          : `${match.path}/4`
                      }
                    />
                  );
                case 6:
                  return (
                    <OnboardingCurrentLocation
                      {...props}
                      stepNumber={currentStep}
                      // TODO: https://incrediblehealth.atlassian.net/browse/WEB-6275
                      // autoProgress
                      nextAction={
                        onboardingData.openToRelocation
                          ? `${match.path}/7`
                          : `${match.path}/9`
                      }
                    />
                  );
                case 7:
                  return (
                    <OnboardingLocationMotivation
                      {...props}
                      stepNumber={currentStep}
                    />
                  );
                case 8:
                  return onboardingData.recent_grad ? (
                    <OnboardingLocation {...props} stepNumber={currentStep} />
                  ) : (
                    <OnboardingLocationPersonalized
                      {...props}
                      stepNumber={currentStep}
                    />
                  );
                case 9:
                  return (
                    <OnboardingLicenses
                      {...props}
                      stepNumber={currentStep}
                      nextAction={`${match.path}/10`}
                      previousAction={
                        onboardingData.openToRelocation
                          ? `${match.path}/8`
                          : `${match.path}/6`
                      }
                    />
                  );
                case 10:
                  return <OnboardingName {...props} stepNumber={currentStep} />;
                case 11:
                  return (
                    <OnboardingEmail
                      {...props}
                      nextAction={`${match.path}/12`}
                      stepNumber={currentStep}
                      urlQuery={queryParams}
                    />
                  );
                case PASSWORD_STEP:
                  return (
                    <OnboardingPassword
                      {...props}
                      stepNumber={currentStep}
                      previousAction="#"
                    />
                  );
                case 13:
                  return (
                    <OnboardingPhoneSimple
                      {...props}
                      stepNumber={currentStep}
                    />
                  );
                case 14:
                  return (
                    <OnboardingResume
                      {...props}
                      stepNumber={currentStep}
                      nextAction={finalAction}
                      collapsedExperienceEntry={Boolean(
                        onboardingData.recent_grad
                      )}
                    />
                  );
                case 15:
                  window.location = finalAction;
                  return null;
                default: {
                  return null;
                }
              }
            }}
          />
        </Switch>
      </BrowserRouter>
    </OnboardingContext.Provider>
  );
};

TalentOnboardContainerQuicksilver.propTypes = {
  getConnectableExperiment: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      stepNumber: PropTypes.string,
    }),
  }).isRequired,
  talentProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  talentProfile: getTalentProfile(state),
  getConnectableExperiment: (featureFlags, id) =>
    getExperimentAssignmentWithoutTracking(
      { featureFlags },
      'web6207_connectable_talent',
      id
    ),
});

export default connect(mapStateToProps)(TalentOnboardContainerQuicksilver);
