import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import qs from 'qs';

import AppProvider from 'shared/components/AppProvider';
import { TalentToasterContainer } from 'shared/components/ihcl/toast';
import { trackAppLoad } from 'shared/helpers/tracking';
import withTracking from 'shared/helpers/withTracking';
import ViewportProvider from 'shared/providers/ViewportProvider';

import EmployerForm from '../components/EmployerForm';
import SignXForm from '../components/non_hiring/SignXForm';
import NonHiringContainer from '../containers/NonHiringContainer';
import SignUpNavTabContainer from '../containers/SignUpNavTabContainer';
import OnboardDisambiguationContainer from '../containers/OnboardDisambiguationContainer';
import TalentOnboardContainer from '../containers/TalentOnboardContainer';
import TalentOnboardLiteContainer, {
  nonHiringIntentRegex,
} from '../containers/TalentOnboardLiteContainer';
import TalentReactivationContainer from '../containers/TalentReactivationContainer';

import TechOnboardContainer from '../containers/TechOnboardContainer'; // !

trackAppLoad('Registration');

const RegistrationApp = ({
  appContextKeys = [],
  resource = null,
  errors = null,
  store,
}) => (
  <AppProvider store={store} appContextKeys={appContextKeys}>
    <ViewportProvider>
      <TalentToasterContainer />
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/users/sign_up" to="/onboard" />

          <Route
            // eslint-disable-next-line react/no-unstable-nested-components
            component={({ location }) => (
              // Preserve the query string during the redirect
              <Redirect to={{ ...location, pathname: '/talent_onboard' }} />
            )}
            path="/users/sign_up/talent"
          />

          <Route
            // eslint-disable-next-line react/no-unstable-nested-components
            component={withTracking(({ location }) => {
              const query = qs.parse(location.search, {
                ignoreQueryPrefix: true,
              });
              return (
                <SignUpNavTabContainer>
                  <EmployerForm
                    initialErrors={errors}
                    previousData={resource}
                    urlEmail={query.email}
                  />
                </SignUpNavTabContainer>
              );
            })}
            path="/users/sign_up/employer"
          />

          <Route
            // eslint-disable-next-line react/no-unstable-nested-components
            component={withTracking(({ match }) => (
              <NonHiringContainer>
                <SignXForm
                  initialErrors={errors}
                  intent={match.params[0]}
                  isSignUp={false}
                  previousData={resource}
                />
              </NonHiringContainer>
            ))}
            path={`/users/sign_in/${nonHiringIntentRegex}`}
          />

          <Route
            path={`/users/:type(sign_up|onboard)/:intent${nonHiringIntentRegex}/:funnelChar?`}
          >
            <TalentOnboardLiteContainer />
          </Route>

          <Route component={OnboardDisambiguationContainer} path="/onboard" />

          <Route component={TalentOnboardContainer} path="/talent_onboard" />

          <Route component={TechOnboardContainer} path="/tech_onboard" />

          <Route
            component={TalentReactivationContainer}
            path="/talent_reactivation"
          />
        </Switch>
      </BrowserRouter>
    </ViewportProvider>
  </AppProvider>
);

RegistrationApp.propTypes = {
  appContextKeys: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
  resource: PropTypes.object,
  store: PropTypes.object.isRequired,
};

export default RegistrationApp;
