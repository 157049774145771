import { styled } from 'shared/components/ihcl/styled';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Button } from 'shared/components/ihcl/button';

const ButtonWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const GoogleSignIn = ({ isSignIn = true, setGoogleJwt }) => {
  const placeholderButton = (
    <Button color="alternate" disabled kind="outline" size="compact">
      Sign {isSignIn ? 'in' : 'up'} with Google
    </Button>
  );

  function handleCredentialResponse(response) {
    setGoogleJwt(response.credential);
  }

  useEffect(() => {
    if (window.google && window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: window.IH.googleOAuthClientId,
        callback: handleCredentialResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('googleSignInDiv'),
        {
          theme: 'outline',
          size: 'large',
          text: isSignIn ? 'signin_with' : 'signup_with',
          width: document.getElementById('googleSignInParent')?.offsetWidth,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ButtonWrapper id="googleSignInParent">
      <div id="googleSignInDiv">{placeholderButton}</div>
    </ButtonWrapper>
  );
};

GoogleSignIn.propTypes = {
  isSignIn: PropTypes.bool,
  setGoogleJwt: PropTypes.func.isRequired,
};

export default GoogleSignIn;
