import { Set } from 'immutable';
import React, { useEffect } from 'react';
import { styled } from 'shared/components/ihcl/styled';
import { Text } from 'shared/components/ihcl/text';
import { getExperimentAssignmentFromFeatureFlags } from 'shared/helpers/experiment';
import { trackExperimentAssignment } from 'shared/helpers/tracking';

const LinkText = styled('a', ({ $theme }) => ({
  ...$theme.typography.ParagraphXSmall,
  color: 'inherit',
  fontWeight: 'bold',
  textDecoration: 'underline',
}));

const smsOptInExperiment = 'web6199_sms_opt_in_fine_print';

export const SmsOptInFinePrint = ({
  onboardingData,
}: {
  onboardingData: { feature_flags?: Set<string>; id?: number };
}) => {
  let smsOptInVariant = '';
  if (!onboardingData.feature_flags) {
    console.error(
      `Missing feature flags; may affect experiment: ${smsOptInExperiment}`
    );
  } else {
    smsOptInVariant = getExperimentAssignmentFromFeatureFlags(
      onboardingData.feature_flags,
      smsOptInExperiment
    );
  }

  useEffect(() => {
    if (smsOptInVariant) {
      trackExperimentAssignment(smsOptInExperiment, smsOptInVariant, {
        talent_profile_id: onboardingData.id,
      });
    }
  }, [onboardingData.id, smsOptInVariant]);

  return smsOptInVariant === 'treatment' ? (
    <Text variant="ParagraphXSmall" color="grayPlaceholder" marginTop="16px">
      View our{' '}
      <LinkText
        href="https://www.incrediblehealth.com/privacy/"
        target="_blank"
      >
        privacy policy
      </LinkText>{' '}
      and{' '}
      <LinkText href="https://www.incrediblehealth.com/terms/" target="_blank">
        terms
      </LinkText>
      . Incredible Health will send you messages related to your job search.
      Message and data rates may apply. Reply HELP for help or STOP to cancel at
      any time.
    </Text>
  ) : (
    <></>
  );
};
