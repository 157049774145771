import { List, Map, OrderedMap, Set } from 'immutable';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled, useStyletron, withStyle } from 'shared/components/ihcl/styled';
import { doPost } from 'shared/helpers/http';

import { locationFromGooglePlace } from 'registration/helpers/talentProfileConversions';
import {
  WorkExperience,
  newWorkExperienceSpecialty,
  newWorkExperienceSpecialtySkill,
  validateWorkExperiences,
} from 'shared/components/WorkExperienceControl';
import { Button } from 'shared/components/ihcl/button';
import { Checkbox } from 'shared/components/ihcl/checkbox';
import { Footnote } from 'shared/components/ihcl/footnote';
import { ArrowRightIcon, UploadIcon } from 'shared/components/ihcl/icon';
import { Input } from 'shared/components/ihcl/input';
import { Modal } from 'shared/components/ihcl/modal';
import { Text } from 'shared/components/ihcl/text';
import {
  Center,
  Left,
  VerticalSpacedItems,
} from 'shared/components/ihcl/positioning';
import { Select } from 'shared/components/ihcl/select';
import { Spinner } from 'shared/components/ihcl/spinner';
import { Textarea } from 'shared/components/ihcl/textarea';
import { ToasterContainer, toaster } from 'shared/components/ihcl/toast';
import { pathToVariant } from 'shared/helpers/activateABTest';
import { useGoogleAutocompleteService } from 'shared/helpers/googlePlaces';
import applyItemsToRailsNestedAttributes from 'shared/helpers/applyItemsToRailsNestedAttributes';
import Sentry from 'shared/helpers/sentry';

import { ExtFile, FileInputButton } from '@files-ui/react';
import debounce from 'just-debounce';
import {
  Onboarding,
  OnboardingSubtitle,
  OnboardingTitle,
  sendUpdate,
} from 'registration/containers/Onboarding';
import { trackClick } from 'shared/helpers/tracking';
import { OnboardingContext } from '../../containers/Onboarding';

const MAX_NURSE_SPECIALTIES_TO_AUTO_POPULATE = 5;

const ExtraLargeTitle = styled('div', {
  fontSize: '48px',
  marginBottom: '8px',
});

const ResumePositionWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: $theme.sizing.scale600,
  marginBottom: $theme.sizing.scale900,
  borderBottomColor: $theme.colors.grayLine,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
}));

const PositionLine = styled('div', ({ $theme }) => ({
  paddingBottom: $theme.sizing.scale600,
}));

const DatesWrapper = withStyle(PositionLine, ({ $theme }) => ({
  display: 'flex',
  columnGap: $theme.sizing.scale600,
  textAlign: 'left',
}));

const SkillsList = styled('ul', {
  listStyleType: 'none',
  paddingLeft: 0,
});

const ExpandManualEntrySection = styled('div', ({ $theme }) => ({
  color: $theme.colors.primary,
}));

const FillManuallyText = styled('div', {
  marginBottom: '24px',
});

const ResumeCTA = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors.gray00,
  marginBottom: $theme.sizing.unit16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'left',
  columnGap: $theme.sizing.unit16,
  paddingTop: $theme.sizing.unit8,
  paddingRight: $theme.sizing.unit16,
  paddingBottom: $theme.sizing.unit8,
  paddingLeft: $theme.sizing.unit16,
  borderRadius: $theme.sizing.unit8,
}));

const ResumeUploadIntroText = styled('div', ({ $theme }) => ({
  color: $theme.colors.ebonyClay,
  fontFamily: 'Manrope',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '150%',
  letterSpacing: '0.25px',
  marginBottom: $theme.sizing.scale800,
}));

const ResumeUploadIconStyled = styled(UploadIcon, ({ $theme }) => ({
  marginLeft: $theme.sizing.scale300,
  marginRight: $theme.sizing.scale200,
}));

const ResumeUploadButtonText = styled('span', ({ $theme }) => ({
  fontFamily: 'Manrope',
  textTransform: 'none',
  fontSize: $theme.sizing.scale600,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '120%',
  letterSpacing: '0.25px',
  paddingTop: $theme.sizing.scale200,
  paddingBottom: $theme.sizing.scale200,
  paddingLeft: $theme.sizing.scale200,
}));

const SkillsRow = styled('li', {});

const dateMatchRE = /([0-9]{2}?)(?:(?: )?(?:\/)(?: )?)([1-2][0-9]{3})/;

const supportedVariants = ['zebra'];

const SpecialtySkills = ({
  instanceId,
  nurseSpecialtySkills,
  selectedSpecialtiesAndSkills,
  updateSpecialtySkills,
}) => {
  const specialtiesWithSkills = OrderedMap(
    selectedSpecialtiesAndSkills
      .filter((sp) => (nurseSpecialtySkills[sp.id] || []).length > 0)
      .map((sp) => [
        sp.id,
        {
          description: sp.description,
          allSkills: nurseSpecialtySkills[sp.id],
          selectedSkills: Set(
            sp.skills
              .filter((wess) => wess._destroy !== true)
              .map((wess) => wess.nurse_specialty_skill_description_id)
          ),
        },
      ])
  );

  return (
    <div>
      {specialtiesWithSkills
        .entrySeq()
        .map(([specialtyId, sws]) => (
          <div key={specialtyId}>
            <Left>
              <OnboardingSubtitle>
                Select {sws.description} skills performed
              </OnboardingSubtitle>
            </Left>
            <SkillsList>
              {sws.allSkills.map((skill) => (
                <SkillsRow key={skill.id}>
                  <Checkbox
                    instanceId={`${instanceId}-${skill.description}`}
                    checked={sws.selectedSkills.includes(skill.id)}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        updateSpecialtySkills(
                          specialtyId,
                          sws.selectedSkills.add(skill.id).toArray()
                        );
                      } else {
                        updateSpecialtySkills(
                          specialtyId,
                          sws.selectedSkills.delete(skill.id).toArray()
                        );
                      }
                    }}
                  >
                    {skill.description}
                  </Checkbox>
                </SkillsRow>
              ))}
            </SkillsList>
          </div>
        ))
        .toArray()}
    </div>
  );
};
SpecialtySkills.propTypes = {
  instanceId: PropTypes.string.isRequired,
  nurseSpecialtySkills: PropTypes.object.isRequired,
  selectedSpecialtiesAndSkills: PropTypes.array.isRequired,
  updateSpecialtySkills: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/require-default-props
const ResumePosition = ({
  instanceId,
  nurseSpecialtyDescriptions = [],
  nurseSpecialtySkills = {},
  setWorkExperience,
  setWorkExperienceError,
  variant = null,
  workExperience,
  workExperienceErrors = Map<string, string>(),
}) => {
  const {
    companyName,
    location,
    jobTitle,
    startMonth,
    startYear,
    endMonth,
    endYear,
    currentlyThere,
    duties,
    workExperienceSpecialties,
  } = workExperience;
  let startDate =
    startMonth && startYear ? `${startMonth} / ${startYear}` : startMonth;
  if (startDate === null || Number.isNaN(startDate)) {
    startDate = undefined;
  }
  let endDate = endMonth && endYear ? `${endMonth} / ${endYear}` : endMonth;
  if (endDate === null || Number.isNaN(endDate) || currentlyThere) {
    endDate = undefined;
  }

  const { profileType = 'nurse' } = useContext(OnboardingContext);

  const [touchedFields, setTouchedFields] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    location ? [{ label: location }] : []
  );
  const selectedSpecialties = workExperienceSpecialties
    .toJS()
    .map((wes) => {
      if (wes._destroy) {
        return null;
      }

      const nurseSpecialtyDescription = nurseSpecialtyDescriptions.find(
        (nsd) => Number(nsd.id) === wes.nurse_specialty_description_id
      );

      if (!nurseSpecialtyDescription) {
        return null;
      }

      const { id, description } = nurseSpecialtyDescription;

      return {
        id,
        description,
        skills: wes.work_experience_specialty_skills,
      };
    })
    .filter((a) => a);

  const updateSpecialtySkills = (specialtyId, selectedSkillIds) => {
    const updatedSpecialties = workExperienceSpecialties.toJS().map((wes) => {
      if (wes._destroy || wes.nurse_specialty_description_id !== specialtyId) {
        return wes;
      }

      const updatedSkills = applyItemsToRailsNestedAttributes(
        selectedSkillIds,
        wes.work_experience_specialty_skills,
        'nurse_specialty_skill_description_id',
        newWorkExperienceSpecialtySkill
      );

      return {
        ...wes,
        work_experience_specialty_skills: updatedSkills,
      };
    });

    setWorkExperience({
      workExperienceSpecialties: updatedSpecialties,
    });
  };

  const autocompleteService = useGoogleAutocompleteService();

  const handleInputChange = (evt) => {
    const { value } = evt.currentTarget;
    if (!value || !autocompleteService) {
      return;
    }
    autocompleteService.getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: 'us' },
        types: ['(cities)'],
      },
      (results) => {
        if (results) {
          const newLocationValues = results.map(locationFromGooglePlace);
          setLocationSuggestions(newLocationValues);
        }
      }
    );
  };

  const selectOverrides = {
    Popover: {
      props: {
        overrides: {
          Body: {
            style: {
              zIndex: 1201, // 1 higher than TalentModal zIndex
            },
          },
        },
      },
    },
  };

  return (
    <ResumePositionWrapper>
      <PositionLine>
        <Input
          label="Company name"
          instanceId={`${instanceId}-company-name`}
          value={companyName}
          onBlur={() => {
            setTouchedFields([...touchedFields, 'companyName']);
          }}
          onChange={(e) => {
            if (workExperienceErrors.has('companyName')) {
              setWorkExperienceError('companyName');
            }
            setWorkExperience({
              companyName: e.currentTarget.value,
            });
          }}
          error={Boolean(
            touchedFields.includes('companyName') &&
              workExperienceErrors.has('companyName')
          )}
        />
      </PositionLine>
      <PositionLine>
        <Select
          data-testid={`${instanceId}-location`}
          placeholder='Location e.g. "Detroit, MI"'
          value={selectedLocation}
          instanceId={`${instanceId}-location`}
          options={locationSuggestions}
          onBlur={() => {
            setTouchedFields([...touchedFields, 'location']);
            setLocationSuggestions([]);
          }}
          maxDropdownHeight="85vh"
          onInputChange={handleInputChange}
          onChange={({ value }) => {
            const newLocation = value.length === 0 ? value : value[0].label;
            if (workExperienceErrors.has('location')) {
              setWorkExperienceError('location');
            }
            setWorkExperience({
              location: newLocation,
            });
            setSelectedLocation(value);
          }}
          error={Boolean(
            touchedFields.includes('location') &&
              workExperienceErrors.has('location')
          )}
          caption={
            touchedFields.includes('location') &&
            workExperienceErrors.get('location')
          }
          overrides={selectOverrides}
        />
      </PositionLine>
      <PositionLine>
        <Input
          label="Title"
          instanceId={`${instanceId}-title`}
          value={jobTitle}
          onBlur={() => {
            setTouchedFields([...touchedFields, 'jobTitle']);
          }}
          onChange={(e) => {
            if (workExperienceErrors.has('jobTitle')) {
              setWorkExperienceError('jobTitle');
            }
            setWorkExperience({
              jobTitle: e.currentTarget.value,
            });
          }}
          error={Boolean(
            touchedFields.includes('jobTitle') &&
              workExperienceErrors.has('jobTitle')
          )}
        />
      </PositionLine>
      <DatesWrapper>
        <div>
          <Input
            value={startDate}
            caption="Start date"
            instanceId={`${instanceId}-start-date`}
            label="MM / YYYY"
            mask="99 / 9999"
            onBlur={() => {
              setTouchedFields([...touchedFields, 'startDate']);
            }}
            onChange={(e) => {
              if (workExperienceErrors.has('startDate')) {
                setWorkExperienceError('startDate');
              }
              const dateMatch = e.currentTarget.value.match(dateMatchRE);
              let newStartMonth;
              let newStartYear;
              if (dateMatch) {
                [, newStartMonth, newStartYear] = dateMatch;
              } else {
                newStartMonth = e.currentTarget.value;
              }
              setWorkExperience({
                startMonth: newStartMonth,
                startYear: newStartYear,
              });
            }}
            error={
              touchedFields.includes('startDate') &&
              workExperienceErrors.has('startDate')
            }
          />
        </div>
        <div>
          <Input
            disabled={currentlyThere}
            value={endDate}
            caption="End date"
            instanceId={`${instanceId}-end-date`}
            label="MM / YYYY"
            mask="99 / 9999"
            onBlur={() => {
              setTouchedFields([...touchedFields, 'endDate']);
            }}
            onChange={(e) => {
              if (workExperienceErrors.has('endDate')) {
                setWorkExperienceError('endDate');
              }
              const dateMatch = e.currentTarget.value.match(dateMatchRE);
              let newEndMonth;
              let newEndYear;
              if (dateMatch) {
                [, newEndMonth, newEndYear] = dateMatch;
              } else {
                newEndMonth = e.currentTarget.value;
              }
              setWorkExperience({
                endMonth: newEndMonth,
                endYear: newEndYear,
              });
            }}
            error={
              !currentlyThere &&
              touchedFields.includes('endDate') &&
              workExperienceErrors.has('endDate')
            }
          />
        </div>
      </DatesWrapper>
      <PositionLine>
        <Checkbox
          instanceId={`${instanceId}-currently-there`}
          checked={currentlyThere}
          onChange={(e) => {
            setWorkExperience({
              currentlyThere: e.target.checked,
            });
          }}
        >
          I currently work here.
        </Checkbox>
      </PositionLine>
      {profileType === 'nurse' && (
        <PositionLine>
          <Select
            multi
            labelKey="description"
            valueKey="id"
            instanceId={`${instanceId}-specialties`}
            options={nurseSpecialtyDescriptions}
            placeholder="Select specialty skills"
            value={selectedSpecialties}
            onChange={({ value }) => {
              if (workExperienceErrors.has('description')) {
                setWorkExperienceError('description');
              }
              const selectedSpecialtyIds = value.map((v) => v.id);

              const updatedSpecialties = applyItemsToRailsNestedAttributes(
                selectedSpecialtyIds,
                workExperienceSpecialties.toJS(),
                'nurse_specialty_description_id',
                newWorkExperienceSpecialty
              );

              setWorkExperience({
                workExperienceSpecialties: updatedSpecialties,
              });
            }}
            error={workExperienceErrors.has('description')}
            overrides={selectOverrides}
          />
          {!supportedVariants.includes(variant) && (
            <SpecialtySkills
              instanceId={`${instanceId}-skills`}
              nurseSpecialtySkills={nurseSpecialtySkills}
              selectedSpecialtiesAndSkills={selectedSpecialties}
              updateSpecialtySkills={updateSpecialtySkills}
            />
          )}
        </PositionLine>
      )}
      {!supportedVariants.includes(variant) && (
        <PositionLine>
          <Textarea
            instanceId={`${instanceId}-duties`}
            placeholder={
              'Briefly describe duties and additional skills ' +
              'performed in this role.'
            }
            value={duties}
            onChange={(e) => {
              setWorkExperience({
                duties: e.currentTarget.value,
              });
            }}
          />
        </PositionLine>
      )}
    </ResumePositionWrapper>
  );
};
ResumePosition.propTypes = {
  instanceId: PropTypes.string.isRequired,
  nurseSpecialtyDescriptions: PropTypes.arrayOf(PropTypes.object),
  nurseSpecialtySkills: PropTypes.object,
  setWorkExperience: PropTypes.func.isRequired,
  setWorkExperienceError: PropTypes.func.isRequired,
  variant: PropTypes.string,
  workExperience: PropTypes.object.isRequired,
  // @ts-ignore
  workExperienceErrors: PropTypes.instanceOf(Map),
};

const UploadResume = ({
  isUploadingResume = false,
  sendResumeToServer,
  uploadButtonText = null,
  uploadResumeForm,
}: {
  isUploadingResume?: boolean;
  sendResumeToServer: (files: ExtFile[]) => void;
  uploadButtonText?: React.ReactNode;
  uploadResumeForm: any;
}) => (
  <>
    <form encType="multipart/form-data" method="POST" ref={uploadResumeForm}>
      <div onClick={debounce(() => trackClick('Resume upload clicked'), 500)}>
        <FileInputButton
          onChange={sendResumeToServer}
          className="resume-file"
          maxFiles={1}
        >
          <ResumeUploadButtonText>{uploadButtonText}</ResumeUploadButtonText>
          <ResumeUploadIconStyled />
        </FileInputButton>
      </div>
    </form>
    <Modal
      closable={false}
      isOpen={isUploadingResume}
      size="full"
      overrides={{
        Close: {
          style: () => ({
            display: 'none',
          }),
        },
      }}
    >
      <VerticalSpacedItems
        style={{
          height: '100%',
          justifyContent: 'space-around',
        }}
      >
        <Center
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Spinner
            style={{
              marginLeft: 'calc(50% - 16px)',
            }}
          />
          <OnboardingSubtitle>Uploading...</OnboardingSubtitle>
        </Center>
      </VerticalSpacedItems>
    </Modal>
  </>
);

const OnboardingResume = ({
  collapsedExperienceEntry = false,
  isReactivation = false,
  nextAction = null,
  onboardingWrapper = null,
  onboardingWrapperProps = {},
  previousAction = null,
  skipOnboardStepUpdate = false,
  stepNumber,
  titleCopyOverride = null,
  subtitleCopyOverride = null,
}) => {
  const {
    history,
    onboardingBasePath,
    onboardingData,
    onboardingOptions,
    profileType = 'nurse',
    setOnboardingData,
  } = useContext(OnboardingContext);
  const [manualSectionExpanded, setManualSectionExpanded] = useState(false);
  const uploadResumeForm = useRef(null);
  const [isUploadingResume, setIsUploadingResume] = useState(false);
  const [workExperienceErrors, setWorkExperienceErrors] = useState(Map());
  const [variantIsSupported, setVariantIsSupported] = useState(false);
  const [, $theme] = useStyletron();
  const {
    first_name: firstName,
    nurseSpecialties,
    workExperiences,
  } = onboardingData;

  const variant = pathToVariant(onboardingBasePath);
  if (variantIsSupported !== supportedVariants.includes(variant)) {
    setVariantIsSupported(supportedVariants.includes(variant));
  }

  const addDefaultSpecialties = (we) => {
    if (we.isEmpty()) {
      const specialtyCount = nurseSpecialties ? nurseSpecialties.size : 0;

      if (
        specialtyCount >= 1 &&
        specialtyCount <= MAX_NURSE_SPECIALTIES_TO_AUTO_POPULATE
      ) {
        return we.set(
          'workExperienceSpecialties',
          nurseSpecialties.keySeq().map(newWorkExperienceSpecialty).toList()
        );
      }
    }

    return we;
  };
  useEffect(() => {
    if (
      workExperiences &&
      workExperiences.size === 1 &&
      workExperiences.get(0).isEmpty() &&
      nurseSpecialties.size <= MAX_NURSE_SPECIALTIES_TO_AUTO_POPULATE
    ) {
      setOnboardingData({
        ...onboardingData,
        workExperiences: workExperiences.set(
          0,
          addDefaultSpecialties(new WorkExperience())
        ),
      });
    }
    if (isReactivation) {
      doPost('/talent_reactivation/complete').catch((error) => {
        console.error(error);
        const errorMsg =
          'Oops, there was a problem connecting ' +
          'to the server. Please check your internet connection ' +
          'and refresh the page.';
        toaster.negative(errorMsg);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const validation = validateWorkExperiences(workExperiences);
    if (validation.errors && !validation.errors.isEmpty()) {
      setWorkExperienceErrors(validation.errors);
    }
  }, [setWorkExperienceErrors, workExperiences]);

  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }
  const {
    nurse_specialty_descriptions: nurseSpecialtyDescriptions,
    nurse_specialty_skills: nurseSpecialtySkills,
  } = onboardingOptions;

  const setWorkExperience = (i) => (workExperienceUpdates) => {
    const newWorkExperience = workExperiences
      .get(i)
      .merge(workExperienceUpdates);
    const newWorkExperiences = List(
      workExperiences.splice(i, 1, newWorkExperience)
    );
    setOnboardingData({
      ...onboardingData,
      workExperienceMethod: 'manual_entry',
      workExperiences: newWorkExperiences,
    });
  };

  const setWorkExperienceError = (i, key, value) => {
    if (value) {
      setWorkExperienceErrors(
        // @ts-ignore
        workExperienceErrors.set(i, workExperienceErrors.get(i).set(key, value))
      );
    } else if (key) {
      setWorkExperienceErrors(
        // @ts-ignore
        workExperienceErrors.set(i, workExperienceErrors.get(i).delete(key))
      );
    } else if (workExperienceErrors.get(i)) {
      setWorkExperienceErrors(workExperienceErrors.delete(i));
    }
  };

  const hasWorkExperience =
    workExperiences.some((we) => !we.isEmpty()) &&
    validateWorkExperiences(workExperiences).errors.isEmpty();

  // eslint-disable-next-line react/require-default-props, react/no-unstable-nested-components
  const sendResumeToServer = (incomingFiles) => {
    const existingFile = onboardingData.resume_filename;

    if (existingFile || incomingFiles.length > 0) {
      const formData = new FormData();
      formData.append('resume', incomingFiles[0].file, incomingFiles[0].name);

      sendUpdate({
        currentStep: stepNumber,
        history,
        nextAction,
        onboardingBasePath,
        onboardingData,
        onboardingUpdates: null,
        profileType,
        setIsUpdating: setIsUploadingResume,
        setOnboardingData,
        formData,
        skipOnboardStepUpdate,
      });

      if (incomingFiles.length > 1) {
        Sentry.captureMessage(
          `Multiple files uploaded ${incomingFiles.length} for resume, only the first file will be used.`
        );
      }
    }
  };

  const copyVariants = {
    default: {
      titleCopy:
        titleCopyOverride || `Congrats, ${firstName}, you're on the last step!`,
      subtitleCopy: subtitleCopyOverride || 'Who is your most recent employer?',
      manualEnterCTACopy: 'Or fill manually ✏️',
    },
    supported_variants: {
      titleCopy:
        titleCopyOverride || `Last one, ${firstName}! Your work history`,
      subtitleCopy:
        subtitleCopyOverride ||
        'We need your most recent work experience to find you the best matches.',
      manualEnterCTACopy: 'Fill manually ✏️',
    },
    collapsed: {
      titleCopy:
        titleCopyOverride || `Last one, ${firstName}! Upload your resume`,
      subtitleCopy:
        subtitleCopyOverride ||
        ' Showcase your skills and boost your interview chances. A resume helps you get noticed by more employers!',
      manualEnterCTACopy: null,
    },
  };

  let copyVariantKey = 'default';

  if (collapsedExperienceEntry) {
    copyVariantKey = 'collapsed';
  } else if (variantIsSupported) {
    copyVariantKey = 'supported_variants';
  }

  const { titleCopy, subtitleCopy, manualEnterCTACopy } =
    copyVariants[copyVariantKey];

  let topResumeSection;
  if (collapsedExperienceEntry) {
    topResumeSection = (
      <>
        <ResumeUploadIntroText>{subtitleCopy}</ResumeUploadIntroText>
        <UploadResume
          isUploadingResume={isUploadingResume}
          sendResumeToServer={sendResumeToServer}
          uploadButtonText="Upload your resume"
          uploadResumeForm={uploadResumeForm}
        />
      </>
    );
  } else if (variantIsSupported) {
    topResumeSection = (
      <ResumeCTA>
        <Text variant="HeadingMedium">👋</Text>
        <span>
          <b>Psst!</b> You can bypass this step by uploading your resume
        </span>
        <UploadResume
          isUploadingResume={isUploadingResume}
          sendResumeToServer={sendResumeToServer}
          uploadResumeForm={uploadResumeForm}
        />
      </ResumeCTA>
    );
  } else {
    topResumeSection = (
      <Footnote
        backgroundColor={$theme.colors.onboardingBackground}
        styles={{ marginBottom: $theme.sizing.unit16 }}
      >
        Bypass this step by
        <UploadResume
          isUploadingResume={isUploadingResume}
          sendResumeToServer={sendResumeToServer}
          uploadButtonText="Uploading your resume"
          uploadResumeForm={uploadResumeForm}
        />
      </Footnote>
    );
  }

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={!hasWorkExperience}
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['workExperiences', 'workExperienceMethod']}
      onboardingWrapper={onboardingWrapper}
      onboardingWrapperProps={onboardingWrapperProps}
      validateBeforeSubmit={() => {
        const validation = validateWorkExperiences(workExperiences);
        if (validation.errors && !validation.errors.isEmpty()) {
          setWorkExperienceErrors(validation.errors);
          return false;
        }
        if (isReactivation) {
          trackClick('Reactivation form completed');
        }
        return true;
      }}
    >
      <Center>
        <ToasterContainer />
        <OnboardingTitle>
          <ExtraLargeTitle>🎉</ExtraLargeTitle>
          <span className="sentry-mask">{titleCopy}</span>
        </OnboardingTitle>
        {collapsedExperienceEntry ? null : (
          <OnboardingSubtitle>{subtitleCopy}</OnboardingSubtitle>
        )}
        {topResumeSection}
        {collapsedExperienceEntry ? (
          <>
            <FillManuallyText>{manualEnterCTACopy}</FillManuallyText>
            {manualSectionExpanded ? null : (
              <ExpandManualEntrySection
                onClick={() => setManualSectionExpanded(true)}
              >
                + Add most recent employment
              </ExpandManualEntrySection>
            )}
          </>
        ) : null}
        {(collapsedExperienceEntry && manualSectionExpanded) ||
        !collapsedExperienceEntry ? (
          <>
            {workExperiences.map((workExperience, i) => (
              <ResumePosition
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                instanceId={`work-experience-entry-${i}`}
                nurseSpecialtyDescriptions={nurseSpecialtyDescriptions}
                nurseSpecialtySkills={nurseSpecialtySkills}
                setOnboardingData={setOnboardingData}
                setWorkExperience={setWorkExperience(i)}
                setWorkExperienceError={(key, value) => {
                  setWorkExperienceError(i, key, value);
                }}
                variant={variant}
                workExperience={workExperience}
                // @ts-ignore
                workExperienceErrors={workExperienceErrors.get(i)}
              />
            ))}
            <Button
              kind="minimal"
              onClick={() => {
                setOnboardingData({
                  ...onboardingData,
                  workExperiences: workExperiences.push(
                    addDefaultSpecialties(new WorkExperience())
                  ),
                });
              }}
            >
              + Add another position
            </Button>
            {collapsedExperienceEntry ? (
              <Button
                kind="minimal"
                onClick={() => {
                  if (isReactivation) {
                    trackClick('Reactivation form completed');
                    trackClick('Reactivation Resume Entry Skipped');
                  } else {
                    trackClick('Onboard Resume Entry Skipped');
                  }
                  if (nextAction.indexOf(onboardingBasePath) === 0) {
                    history.push(nextAction);
                  } else {
                    window.location = nextAction;
                  }
                }}
              >
                <ArrowRightIcon /> Skip for now
              </Button>
            ) : null}
          </>
        ) : null}
      </Center>
    </Onboarding>
  );
};
OnboardingResume.propTypes = {
  collapsedExperienceEntry: PropTypes.bool,
  isReactivation: PropTypes.bool,
  nextAction: PropTypes.string,
  onboardingWrapper: PropTypes.func,
  onboardingWrapperProps: PropTypes.object,
  previousAction: PropTypes.string,
  skipOnboardStepUpdate: PropTypes.bool,
  stepNumber: PropTypes.number.isRequired,
  subtitleCopyOverride: PropTypes.string,
  titleCopyOverride: PropTypes.string,
};

export default OnboardingResume;
