import React from 'react';
import { OnboardingTitle } from 'registration/components/new_pages/OnboardingCurrentLocation/OnboardingTitle';
import { OtherLocations } from 'registration/components/new_pages/OnboardingCurrentLocation/OtherLocations';
import { TagLocationPinIcon } from 'registration/containers/Onboarding';
import { Button } from 'shared/components/ihcl/button';
import { Center } from 'shared/components/ihcl/positioning';
import { Tag, SIZE as TAG_SIZE } from 'shared/components/ihcl/tag';

type ExistingLocationProps = {
  currentLocation: {
    city: string;
    label: string;
    state: string;
    zipCode?: string;
  };
  isRecentGrad?: boolean;
  openToRelocation?: boolean;
  setCurrentLocation: Function;
  setOpenToRelocation: Function;
  setStillAtCurrentLocation: Function;
  stillAtCurrentLocation?: boolean;
};
export const ExistingLocation = ({
  currentLocation,
  setCurrentLocation,
  isRecentGrad = false,
  openToRelocation = null,
  setOpenToRelocation,
  stillAtCurrentLocation = null,
  setStillAtCurrentLocation,
}: ExistingLocationProps) => (
  <Center>
    <OnboardingTitle>Are you still living here?</OnboardingTitle>
    <Center>
      <Tag
        style={{
          marginTop: '16px',
        }}
        $fadeIn
        $fullWidth
        closeable={false}
        size={TAG_SIZE.medium}
      >
        <div>
          <TagLocationPinIcon />
          {currentLocation.label}
        </div>
      </Tag>
      <br />
      <div
        style={{
          marginTop: '16px',
        }}
      />
      <Button
        onClick={() => {
          setStillAtCurrentLocation(false);
          setCurrentLocation(null);
        }}
        kind={stillAtCurrentLocation ? 'tertiary' : 'primary'}
        style={{
          minWidth: '100px',
          marginRight: '32px',
        }}
      >
        No
      </Button>
      <Button
        onClick={() => setStillAtCurrentLocation(true)}
        kind={stillAtCurrentLocation ? 'primary' : 'tertiary'}
        style={{
          minWidth: '100px',
        }}
      >
        Yes
      </Button>
      <div
        style={{
          visibility: stillAtCurrentLocation ? 'visible' : 'hidden',
          marginTop: '40px',
        }}
      >
        <OtherLocations
          openToRelocation={openToRelocation}
          setOpenToRelocation={setOpenToRelocation}
          isRecentGrad={isRecentGrad}
        />
      </div>
    </Center>
  </Center>
);
