import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { styled, withStyle } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { Slideshow } from 'shared/components/ihcl/slideshow';
import { VerticalSpacedItems } from 'shared/components/ihcl/positioning';

import bestWork from 'images/illustrations/nurse-app-hospital.svg';
import continuingEducation from 'images/illustrations/nurse-certification.svg';
import inDemand from 'images/illustrations/nurse-app-interaction.svg';
import nurseHelp from 'images/illustrations/nurse-connection.svg';

import {
  OnboardingContext,
  RestrictedWidthContainer,
} from '../../containers/Onboarding';

import 'stylesheets/partials/ihcl/slideshow.scss';

const PageContainer = withStyle(VerticalSpacedItems, {
  minHeight: '100vh',
  justifyContent: 'space-around',
});

const SlidesContainer = styled('div', {
  marginLeft: '24px',
  marginRight: '24px',
  marginTop: '25px',
});

const ButtonContainer = withStyle(VerticalSpacedItems, {
  alignItems: 'center',
});

const OnboardingEducation = ({ nextAction }) => {
  const { history }: any = useContext(OnboardingContext);

  useEffect(() => {
    window.mixpanel.track('Onboard Education Slide 1 Viewed');
  }, []);

  return (
    <RestrictedWidthContainer>
      <PageContainer>
        <SlidesContainer>
          <Slideshow
            onChange={(oldIndex, newIndex) => {
              window.mixpanel.track(
                `Onboard Education Slide ${newIndex + 1} Viewed`
              );
            }}
            slides={[
              {
                title: 'Find and do your best work.',
                image: bestWork,
                imageAlt: `Illustration of Incredible Health empowering a
 nurse`,
                text: `Get matched with your dream job. Get support from real
 nurses. Get access to free CEUs and more. `,
              },
              {
                title: 'You’re in demand!',
                image: inDemand,
                imageAlt: 'Illustration of responding to interview requests',
                text: `Create a profile and sit back. Accept or decline
 interview requests from top employers that are matched to you. You are in
 control!`,
              },
              {
                title: 'Real nurses helping you.',
                image: nurseHelp,
                imageAlt: `Illustration of connecting with a nurse at Incredible
 Health`,
                text: `Your personal nurse concierge guides you in the hiring
 process - resume tips, interview prep, and job offer evaluation. You are in
 good hands.`,
              },
              {
                title: 'Free ANCC accredited CEUs.',
                image: continuingEducation,
                imageAlt: 'Illustration of completed CEUs',
                text: `Nurses should never have to pay for CEUs so here you get
 them 100% free. Access CEUs and an exclusive nurse community.`,
              },
            ]}
          />
        </SlidesContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              history.push(nextAction);
            }}
          >
            Get job matches!
          </Button>
          <Button
            kind="minimal"
            onClick={() => {
              window.location.href = '/users/sign_in';
            }}
          >
            Sign In
          </Button>
        </ButtonContainer>
      </PageContainer>
    </RestrictedWidthContainer>
  );
};

OnboardingEducation.propTypes = {
  nextAction: PropTypes.string.isRequired,
};

export default OnboardingEducation;
