import React from 'react';
import { withStyle, Theme } from 'shared/components/ihcl/styled';
import {
  Tag as BaseTag,
  StyledRoot as BaseStyledRoot,
  StyledText as BaseStyledText,
  SIZE as BASE_SIZE,
  VARIANT,
} from 'baseui/tag';

export const SIZE = {
  ...BASE_SIZE,
  tiny: 'tiny',
};

export { KIND } from 'baseui/tag';

type StyledRootProps = {
  $borderRadius?:
    | string
    | {
        borderTopLeftRadius: string | 0;
        borderTopRightRadius: string | 0;
        borderBottomLeftRadius: string | 0;
        borderBottomRightRadius: string | 0;
      };
  $borderWidth?: string;
  $containerWidth?: boolean;
  $size?: keyof typeof SIZE;
  $variant?: keyof typeof VARIANT;
  $theme?: Theme;
  $margins?: {
    marginTop: string | 0;
    marginRight: string | 0;
    marginBottom: string | 0;
    marginLeft: string | 0;
  };
};

type StyledRootOptionalStyles = {
  borderTopLeftRadius?: string | 0;
  borderTopRightRadius?: string | 0;
  borderBottomLeftRadius?: string | 0;
  borderBottomRightRadius?: string | 0;
  marginTop?: string | 0;
  marginRight?: string | 0;
  marginBottom?: string | 0;
  marginLeft?: string | 0;
};

export const StyledRoot = withStyle<any, StyledRootProps>(
  BaseStyledRoot,
  ({
    $containerWidth,
    $size,
    $variant,
    $theme,
    $margins,
    $borderRadius,
    $borderWidth = null,
  }) => {
    const borderColor = $theme.colors.primary10;
    let borderWidth = $variant === 'solid' ? 0 : $theme.borders.tagBorderWidth;
    const color =
      $variant === 'solid' ? $theme.colors.primary : $theme.colors.gray;
    const optionalStyles: StyledRootOptionalStyles = {};
    if ($borderWidth !== null) {
      borderWidth = $borderWidth;
    }
    if (typeof $margins === 'object') {
      const { marginTop, marginRight, marginBottom, marginLeft } = $margins;
      optionalStyles.marginTop = marginTop;
      optionalStyles.marginRight = marginRight;
      optionalStyles.marginBottom = marginBottom;
      optionalStyles.marginLeft = marginLeft;
    }
    if ($borderRadius && typeof $borderRadius === 'object') {
      const {
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
      } = $borderRadius;
      optionalStyles.borderTopLeftRadius = borderTopLeftRadius;
      optionalStyles.borderTopRightRadius = borderTopRightRadius;
      optionalStyles.borderBottomRightRadius = borderBottomRightRadius;
      optionalStyles.borderBottomLeftRadius = borderBottomLeftRadius;
    } else if (typeof $borderRadius === 'string') {
      optionalStyles.borderTopLeftRadius = $borderRadius;
      optionalStyles.borderTopRightRadius = $borderRadius;
      optionalStyles.borderBottomRightRadius = $borderRadius;
      optionalStyles.borderBottomLeftRadius = $borderRadius;
    }
    const sharedStyles = {
      borderTopWidth: borderWidth,
      borderRightWidth: borderWidth,
      borderBottomWidth: borderWidth,
      borderLeftWidth: borderWidth,
      borderTopColor: borderColor,
      borderRightColor: borderColor,
      borderBottomColor: borderColor,
      borderLeftColor: borderColor,
      color,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      width: undefined,
      ...optionalStyles,
    };
    // This is temporary until the employer tag style is fixed to not be blue on blue
    const sharedStylesNoColor = { ...sharedStyles };
    delete sharedStylesNoColor.color;
    if ($containerWidth) {
      sharedStyles.width = '100%';
    }
    if ($size === SIZE.small) {
      return {
        ...sharedStylesNoColor,
        fontSize: $theme.typography.font200.fontSize,
        lineHeight: '19px',
      };
    }
    if ($size === SIZE.medium) {
      return {
        ...sharedStyles,
        fontSize: $theme.typography.font200.fontSize,
        lineHeight: '18px',
        height: '34px',
      };
    }
    if ($size === SIZE.tiny) {
      return {
        ...sharedStyles,
        fontSize: '12px',
        lineHeight: '15px',
        height: '24px',
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingTop: '4px',
        paddingBottom: '4px',
      };
    }
    return { ...sharedStylesNoColor };
  }
);

type StyledTextProps = {
  $maxWidth?: string | 0;
  $textAlign?: string;
  $textColor?: string;
  $textStyle?: 'normal' | 'bold';
  $theme?: Theme;
  $width?: string | 0;
};

type StyledTextStyles = {
  maxWidth?: string | 0;
  fontWeight?: number;
  color?: string;
  textAlign?: string;
  width?: string | 0;
};

export const StyledText = withStyle<any, StyledTextProps>(
  BaseStyledText,
  // @ts-ignore
  ({ $maxWidth, $textColor, $textStyle, $textAlign, $theme, $width }) => {
    const styles: StyledTextStyles = {
      maxWidth: $maxWidth || $theme.sizing.scale3200,
      fontWeight: $textStyle === 'bold' ? 800 : 500,
    };
    if ($textColor) {
      styles.color = $textColor;
    }
    if ($textAlign) {
      styles.textAlign = $textAlign;
    }
    if ($width) {
      styles.width = $width;
    }
    return styles;
  }
);

export const StyledFadeInRoot = withStyle(StyledRoot, () => ({
  animationDuration: '0.5s',
  animationIterationCount: '1',
  animationName: {
    from: {
      opacity: '0',
    },
    to: {
      opacity: '1',
    },
  },
}));

type TagProps = {
  $borderRadius?: string | object;
  $borderWidth?: string | 0;
  $containerWidth?: boolean;
  $fadeIn?: boolean;
  $fullWidth?: boolean;
  $margins?: object;
  $textColor?: string;
  $textStyle?: 'normal' | 'bold';
  'aria-selected'?: boolean;
  color?: string;
  overrides?: any;
  role?: string;
  size?: string;
  style?: object;
  variant?: keyof typeof VARIANT;
  [key: string]: any;
};

type TagTextProps = {
  $maxWidth?: string | 0;
  $textAlign?: string;
  $textColor?: string;
  $textStyle?: 'normal' | 'bold';
  $width?: string | 0;
};

export const Tag = ({ ...props }: TagProps) => {
  const rootProps = {
    $borderRadius: props.$borderRadius,
    $borderWidth: props.$borderWidth,
    $containerWidth: props.$containerWidth,
    $size: props.size,
    $variant: props.variant,
    $margins: props.$margins,
    role: props.role || 'button',
    style: {
      borderTopColor: undefined,
      borderRightColor: undefined,
      borderBottomColor: undefined,
      borderLeftColor: undefined,
      ...props.style,
    },
    'aria-selected': props['aria-selected'],
  };
  const textProps: TagTextProps = {
    $textStyle: props.$textStyle || 'bold',
  };
  if (props.color && props.variant !== VARIANT.solid) {
    rootProps.style.borderTopColor = props.color;
    rootProps.style.borderRightColor = props.color;
    rootProps.style.borderBottomColor = props.color;
    rootProps.style.borderLeftColor = props.color;
  }
  if (props.$textColor) {
    textProps.$textColor = props.$textColor;
  }
  if (props.$containerWidth) {
    textProps.$textAlign = 'center';
    textProps.$width = '100%';
  }
  if (props.$containerWidth || props.$fullWidth) {
    textProps.$maxWidth = 'unset';
  }
  const Root = props.$fadeIn ? StyledFadeInRoot : StyledRoot;
  return (
    // @ts-ignore
    <BaseTag
      {...props}
      overrides={{
        ...(props.overrides || {}),
        Root: {
          ...props.overrides?.Root,
          component: Root,
          props: rootProps,
        },
        Text: {
          component: StyledText,
          props: textProps,
        },
      }}
    />
  );
};
