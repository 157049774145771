import React from 'react';
import { styled } from 'shared/components/ihcl/styled';

import { DatePicker as BaseDatePicker } from 'baseui/datepicker';

import { DownIcon, NextIcon, PreviousIcon } from './icon';

export { ORIENTATION } from 'baseui/datepicker';

export const SIZE = {
  default: 'default',
  compact: 'compact',
};

const DownWrapper = styled('div', ({ $theme }) => ({
  color: $theme.colors.primary,
  marginLeft: '12px',
}));

const DownIndicator = () => (
  <DownWrapper>
    <DownIcon />
  </DownWrapper>
);

type DatePickerProps = {
  range?: boolean;
  popoverZIndex?: number;
  [key: string]: any;
};

export const DatePicker = ({
  range = false,
  popoverZIndex = null,
  ...props
}: DatePickerProps) => (
  <BaseDatePicker
    formatString="MM/dd/yyyy"
    mask={range ? '99 / 99 / 9999 - 99 / 99 / 9999' : '99 / 99 / 9999'}
    placeholder={range ? 'MM / DD / YYYY - MM / DD / YYYY' : 'MM / DD / YYYY'}
    {...props}
    range={range}
    overrides={{
      Popover: {
        props: {
          overrides: {
            Body: {
              style: {
                zIndex: popoverZIndex,
              },
            },
          },
        },
      },
      MonthYearSelectPopover: {
        props: {
          overrides: {
            Body: {
              style: {
                zIndex: popoverZIndex,
              },
            },
          },
        },
      },
      MonthYearSelectIconContainer: DownIndicator,
      NextButtonIcon: {
        props: {
          overrides: {
            Svg: {
              component: NextIcon,
              props: {
                height: '16px',
                width: '16px',
                style: {
                  position: 'relative',
                  top: '5px',
                },
              },
            },
          },
        },
      },
      PrevButtonIcon: {
        props: {
          overrides: {
            Svg: {
              component: PreviousIcon,
              props: {
                height: '16px',
                width: '16px',
                style: {
                  position: 'relative',
                  top: '5px',
                },
              },
            },
          },
        },
      },
    }}
  />
);
