import lodash from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const fullYearOptions = Array.from({ length: 50 }, (v, i) => i).map((i) => {
  const curYear = new Date().getFullYear();
  const yearIter = curYear - i;
  return { value: yearIter, label: String(yearIter) };
});

const monthOptions = Array.from({ length: 12 }, (v, i) => i + 1).map((i) => ({
  value: i,
  label: lodash.padStart(i, 2, '0'),
}));

const MonthSelector = ({
  disabled = false,
  id,
  monthValue,
  onMonthChange,
  onYearChange,
  yearValue,
}) => {
  let monthId = null;
  let yearId = null;
  if (id) {
    monthId = `${id}-month`;
    yearId = `${id}-year`;
  }

  return (
    <Row>
      <Col xs={6}>
        <Select
          clearable={false}
          disabled={disabled}
          instanceId={yearId}
          onChange={onYearChange}
          options={fullYearOptions}
          placeholder="Year"
          value={yearValue}
        />
      </Col>
      <Col xs={6}>
        <Select
          clearable={false}
          disabled={disabled}
          instanceId={monthId}
          onChange={onMonthChange}
          options={monthOptions}
          placeholder="Month"
          value={monthValue}
        />
      </Col>
    </Row>
  );
};

MonthSelector.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  monthValue: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number])
    .isRequired,
  onMonthChange: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired,
  yearValue: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number])
    .isRequired,
};

export default MonthSelector;
