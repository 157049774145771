import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalBody as BaseModalBody,
  Modal as BaseModal,
  ModalHeader as BaseModalHeader,
  ModalFooter as BaseModalFooter,
  StyledDialog as BaseStyledDialog,
} from 'baseui/modal';
import { withStyle } from '../styled';
import CloseButton from './CloseButton';
import {
  EmployerModalProvider,
  useEmployerModal,
} from './EmployerModalProvider';
import { elevatedModalZIndex } from '../shared';

export {
  ModalButton,
  FocusOnce,
  SIZE,
  ROLE,
  CLOSE_SOURCE,
  StyledRoot,
  StyledDialogContainer,
  StyledClose,
} from 'baseui/modal';

export const Modal = BaseModal;
export const StyledDialog = BaseStyledDialog;
export const ModalBody = BaseModalBody;
export const ModalHeader = BaseModalHeader;
export const ModalFooter = BaseModalFooter;

export const EmployerDialog = withStyle(
  BaseStyledDialog,
  ({ width, $theme }) => ({
    outline: `${$theme.colors.alto} solid`,
    borderBottomLeftRadius: $theme.sizing.unit8,
    borderBottomRightRadius: $theme.sizing.unit8,
    borderTopLeftRadius: $theme.sizing.unit8,
    borderTopRightRadius: $theme.sizing.unit8,
    backgroundColor: $theme.colors.wildSand,
    boxShadow: '0px 8px 24px 4px #00000029',
    width,
  })
);

export const EmployerModal = ({
  elevateZIndex = false,
  width = null,
  closeAction = null,
  ...props
}) => (
  <EmployerModalProvider closeAction={closeAction}>
    <BaseModal
      {...props}
      closeable={false} // If you want to make this closeable, use the closeAction prop.
      overrides={{
        Root: {
          style: elevateZIndex
            ? {
                zIndex: elevatedModalZIndex,
              }
            : {},
        },
        Dialog: {
          component: EmployerDialog,
          props: {
            width,
          },
        },
      }}
    />
  </EmployerModalProvider>
);

EmployerModal.propTypes = {
  closeAction: PropTypes.func,
  elevateZIndex: PropTypes.bool,
  width: PropTypes.string,
};

export const EmployerModalBody = withStyle(BaseModalBody, ({ $theme }) => ({
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: $theme.colors.iron,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: $theme.colors.iron,
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: $theme.sizing.scale800,
  paddingRight: $theme.sizing.scale800,
  paddingTop: '32px',
  paddingBottom: '32px',
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.scale1000,
}));

export const StyledEmployerModalHeader = withStyle(
  BaseModalHeader,
  ({ $theme }) => ({
    marginTop: $theme.sizing.scale800,
    marginBottom: $theme.sizing.scale800,
    marginLeft: $theme.sizing.scale800,
    marginRight: $theme.sizing.scale800,
    fontWeight: 800,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
);

export const EmployerModalHeader = ({ children = null, ...props }) => {
  const { closeAction } = useEmployerModal();
  return (
    <StyledEmployerModalHeader {...props}>
      {children}
      {closeAction && <CloseButton onClick={closeAction} />}
    </StyledEmployerModalHeader>
  );
};
EmployerModalHeader.propTypes = {
  children: PropTypes.node,
};

export const EmployerModalFooter = withStyle(BaseModalFooter, ({ $theme }) => ({
  marginTop: $theme.sizing.scale600,
  marginBottom: $theme.sizing.scale600,
  marginLeft: $theme.sizing.scale800,
  marginRight: $theme.sizing.scale800,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: $theme.sizing.scale300,
}));

export const TalentDialog = withStyle(BaseStyledDialog, ({ $theme }) => ({
  paddingTop: $theme.sizing.scale1400,
  paddingBottom: $theme.sizing.scale1400,
  paddingLeft: $theme.sizing.scale800,
  paddingRight: $theme.sizing.scale800,
  borderStartStartRadius: $theme.sizing.scale800,
  borderStartEndRadius: $theme.sizing.scale800,
  borderEndStartRadius: $theme.sizing.scale800,
  borderEndEndRadius: $theme.sizing.scale800,
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.scale600,
}));

export const TalentModal = ({
  zIndex = 1200, // To ensure we overlay elements on /talent/profile
  useTalentDialog = true,
  overrides = {},
  ...props
}) => (
  <BaseModal
    {...props}
    overrides={{
      ...overrides,
      Root: {
        ...overrides?.Root,
        style: {
          ...overrides?.Root?.style,
          ...(zIndex ? { zIndex } : {}),
        },
      },
      Dialog: useTalentDialog
        ? TalentDialog
        : overrides?.Dialog || BaseStyledDialog,
    }}
  />
);
TalentModal.propTypes = {
  overrides: PropTypes.object,
  useTalentDialog: PropTypes.bool,
  zIndex: PropTypes.number,
};
