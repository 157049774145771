import React from 'react';

import { styled, useStyletron, withStyle } from 'shared/components/ihcl/styled';
import { Sizing } from 'baseui/themes/types';
import { Spinner as BaseSpinner, SIZE } from 'baseui/spinner';

export { SIZE } from 'baseui/spinner';

export const SpinnerWrapper = styled<'div', { $height: string }>(
  'div',
  ({ $height }) => ({
    height: $height,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export const Spinner = withStyle(BaseSpinner, {
  backgroundColor: 'transparent',
});

export type WrappedSpinnerProps = {
  height?: string | keyof Sizing;
  spinnerSize?: number | string | keyof Sizing | keyof typeof SIZE;
};

export const WrappedSpinner = ({
  height = null,
  spinnerSize = SIZE.medium,
}: WrappedSpinnerProps) => {
  const [, theme] = useStyletron();
  let wrapperHeight = height;
  if (!wrapperHeight) {
    wrapperHeight = {
      large: theme.sizing.scale1000,
      medium: theme.sizing.scale900,
      small: theme.sizing.scale800,
    }[spinnerSize];
  }
  return (
    <SpinnerWrapper $height={wrapperHeight}>
      <Spinner $size={spinnerSize} />
    </SpinnerWrapper>
  );
};

const InlineSpinnerWrapper = styled('span', ({ $theme }) => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: $theme.sizing.scale200,
}));

export type InlineSpinnerProps = {
  spinnerSize?: number | string | keyof Sizing | keyof typeof SIZE;
};

export const InlineSpinner = ({ spinnerSize = 18 }: InlineSpinnerProps) => (
  <InlineSpinnerWrapper>
    <Spinner $size={spinnerSize} />
  </InlineSpinnerWrapper>
);
