import { OrderedMap, Set } from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import reduxSentryMiddleware from 'shared/helpers/reduxSentryMiddleware';
import rootReducer from '../reducers';

const configureStore = (railsProps) => {
  const stateOptions = OrderedMap(
    railsProps.states?.map((state) => [
      state.id,
      {
        value: state.id,
        label: state.name,
        abbrv: state.abbreviation,
        compact: state.compact,
      },
    ])
  );
  const states = OrderedMap(
    railsProps.states?.map((state) => [state.id, state])
  );

  const initialState = {
    careSettings: railsProps.care_settings,
    employerProfile: railsProps.employer_profile,
    featureFlags: Set(railsProps.feature_flags),
    nurse_specialty_descriptions: railsProps.nurse_specialty_descriptions,
    nurse_specialty_skills: railsProps.nurse_specialty_skills,
    states,
    stateOptions,
    talent_profile: railsProps.talent_profile,
    tech_profile: railsProps.tech_profile,
  };

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhance = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhance(applyMiddleware(thunk), ...reduxSentryMiddleware())
  );

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
