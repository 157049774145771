import React from 'react';
import { createRoot } from 'react-dom/client';
import RegistrationApp from './RegistrationApp';
import configureStore from '../store/talentOnboardStore';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('registration-app');
  const root = createRoot(node);
  const data = JSON.parse(node.getAttribute('data'));
  const store = configureStore(data);
  const appContextKeys = [
    'states',
    'tech_certifications',
    'tech_job_descriptions',
  ];

  root.render(
    <RegistrationApp store={store} appContextKeys={appContextKeys} {...data} />
  );
});
