import { styled } from 'shared/components/ihcl/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { updateTalentProfile } from 'shared/actions/talentProfileActions';
import withGoogleOptimize from 'shared/helpers/withGoogleOptimize';
import {
  trackEvent,
  trackNonHiringOnboardPageView,
} from 'shared/helpers/tracking';

import {
  convertToState,
  convertStateToAttributes,
  talentProfileAttributesWouldUpdateServer,
} from '../../helpers/talentProfileConversions';
import { getTalentProfile, getUiState } from '../../selectors';
import { OnboardingStepProvider } from './providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from './providers/OnboardingVariantProvider';

const SignUpWrapper = styled('div', ({ $justifyFlexStart }) => ({
  minWidth: '320px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: $justifyFlexStart ? 'flex-start' : 'space-between',
  height: 'calc(var(--vh, 1vh) * 100)',
  overflow: 'auto',
  maxWidth: '768px',
  margin: '0 auto',
  paddingTop: '34px',
  paddingBottom: '34px',
  width: '100%',
  paddingLeft: '16px',
  paddingRight: '16px',
  textAlign: 'center',
  '@media screen and (max-width: 340px)': {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const setVhProperty = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const OnboardingStep = ({
  children,
  dispatch,
  justifyFlexStart = false,
  bodyClasses = '',
  serverTalentProfile = null,
  stepName,
  uiState,
}) => {
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(
    uiState.get('profileSaving')
  );
  const {
    currentStep,
    goToPreviousPage,
    intent,
    onboardVariant,
    setMostRecentCompletedStep,
    setTalentProfileUpdates,
    talentProfileUpdates,
  } = useOnboardingVariantContext();
  const [localStepTalentProfile, setLocalStepTalentProfile] =
    useState(talentProfileUpdates);
  const [talentProfileKeys, setTalentProfileKeys] = useState(() => new Set());
  const addTalentProfileKeys = (keys) => {
    const missingKeys = keys.filter((key) => !talentProfileKeys.has(key));
    if (missingKeys) {
      setTalentProfileKeys((prev) => new Set([...prev, ...missingKeys]));
    }
  };

  const sharedEventProperties = {
    intent,
    onboard_variant: onboardVariant,
  };

  useEffect(() => {
    setVhProperty();
    trackNonHiringOnboardPageView();
    trackEvent(`Onboard Lite Step ${stepName} Started`, sharedEventProperties);
  }, [stepName]);

  useEffect(() => {
    if (isUpdatingProfile !== uiState.get('profileSaving')) {
      setIsUpdatingProfile(uiState.get('profileSaving'));
    }
  }, [isUpdatingProfile, uiState]);

  useEffect(() => {
    setLocalStepTalentProfile((localTp) => ({
      ...convertToState(serverTalentProfile, talentProfileKeys),
      ...talentProfileUpdates,
      ...localTp,
    }));
  }, [serverTalentProfile, talentProfileUpdates, talentProfileKeys]);

  const completeStep = (newTalentProfileUpdates) => {
    setTalentProfileUpdates(newTalentProfileUpdates);
    trackEvent(
      `Onboard Lite Step ${stepName} Completed`,
      sharedEventProperties
    );
    setMostRecentCompletedStep(currentStep);
  };
  const maybeGoToNext = () => {
    const updatedTalentProfile = {
      ...talentProfileUpdates,
      ...localStepTalentProfile,
    };
    const profileAttributes = convertStateToAttributes(
      updatedTalentProfile,
      Object.keys(updatedTalentProfile),
      serverTalentProfile
    );
    const shouldUpdateTalentProfile = talentProfileAttributesWouldUpdateServer(
      profileAttributes,
      serverTalentProfile
    );

    if (serverTalentProfile.id != null && shouldUpdateTalentProfile) {
      // Update the server with the new talent profile attributes.
      let profileAttributesWithStep = profileAttributes;
      if (currentStep > serverTalentProfile.current_onboard_step) {
        profileAttributesWithStep = {
          ...profileAttributes,
          current_onboard_step: currentStep,
        };
      }
      setIsUpdatingProfile(true);
      dispatch(
        updateTalentProfile(
          serverTalentProfile.id,
          profileAttributesWithStep,
          () => {
            setIsUpdatingProfile(false);
            completeStep({});
          }
        )
      );
    } else if (serverTalentProfile.id != null) {
      // The talent profile is already up to date with the server talent profile
      completeStep({});
    } else {
      // Roll over the updated attributes to the next page.
      completeStep(updatedTalentProfile);
    }
  };

  return (
    <OnboardingStepProvider
      config={{
        addTalentProfileKeys,
        completeStep,
        isUpdatingProfile,
        localStepTalentProfile,
        maybeGoToNext,
        maybeGoToPrevious: goToPreviousPage,
        setLocalStepTalentProfile,
      }}
    >
      <div className="non-hiring">
        <Helmet>
          <body className={`${bodyClasses} registration-app`} />
        </Helmet>
        <SignUpWrapper $justifyFlexStart={justifyFlexStart}>
          {children}
        </SignUpWrapper>
      </div>
    </OnboardingStepProvider>
  );
};

OnboardingStep.propTypes = {
  bodyClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  justifyFlexStart: PropTypes.bool,
  serverTalentProfile: PropTypes.object,
  stepName: PropTypes.string.isRequired,
  uiState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  serverTalentProfile: getTalentProfile(state),
  uiState: getUiState(state),
});

export default withGoogleOptimize(connect(mapStateToProps)(OnboardingStep));
