import React from 'react';
import { LucideProps } from 'lucide-react';

import { Sizing } from 'shared/components/ihcl/theme-provider';
import { styled, useStyletron } from './styled';
import { lucideIcons } from './lucideIcons';

interface LucideIconProps extends LucideProps {
  name: keyof typeof lucideIcons;
  size?: Sizing | `${number}em` | `${number}px`;
}

export type LucideIconName = keyof typeof lucideIcons;

export const LucideIcon = ({
  name,
  size = '1em',
  ...props
}: LucideIconProps) => {
  const [, theme] = useStyletron();
  const Icon = lucideIcons[name];

  return <Icon size={theme.sizing[size] || size} {...props} />;
};

const Wrapper = styled<'span', { $verticalAlign: string }>(
  'span',
  ({ $verticalAlign }) => ({
    verticalAlign: $verticalAlign,
  })
);

interface WrappedLucideIconProps extends LucideIconProps {
  $verticalAlign?: 'bottom' | 'middle' | 'text-bottom';
}

export const WrappedLucideIcon = ({
  name,
  $verticalAlign = 'middle',
  ...props
}: WrappedLucideIconProps) => {
  const { color, ...rest } = props;
  const wrapperStyle = {
    ...(color ? { color } : {}),
  };

  return (
    <Wrapper $verticalAlign={$verticalAlign} style={wrapperStyle}>
      <LucideIcon name={name} {...rest} />
    </Wrapper>
  );
};
