import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'react-inlinesvg';

import answerIconPath from 'images/icons/advice/answer.svg';
import filterPath from 'images/icons/advice/filter.svg';
import followIconPath from 'images/icons/advice/follow.svg';
import askQuestionPath from 'images/icons/advice/pencil.svg';
import searchPath from 'images/icons/advice/search.svg';
import unfollowIconPath from 'images/icons/advice/unfollow.svg';
import alertIconPath from 'images/icons/alertCircle.svg';
import circleCheckHalfPath from 'images/icons/circleCheckHalf.svg';
import alertTriangleIconPath from 'images/icons/alertTriangle.svg';
import appIconPath from 'images/icons/app-icon.svg';
import archivePath from 'images/icons/archive-icon.svg';
import arrowSendPath from 'images/icons/arrow-send.svg';
import arrowUpNarrowWideFourPath from 'images/icons/arrow-up-narrow-wide-four.svg';
import arrowUpNarrowWideTwoPath from 'images/icons/arrow-up-narrow-wide-two.svg';
import awardIconPath from 'images/icons/award.svg';
import backArrowThinPath from 'images/icons/back-arrow-thin.svg';
import bookOpenCheckPath from 'images/icons/book-open-check.svg';
import briefcasePath from 'images/icons/briefcase.svg';
import businessDealPath from 'images/icons/business-deal.svg';
import calendarCheckIconPath from 'images/icons/calendar-check.svg';
import calendarClockIconPath from 'images/icons/calendar-clock.svg';
import calendarHeartIconPath from 'images/icons/calendar-heart.svg';
import calendarIconPath from 'images/icons/calendar-simple.svg';
import callIconPath from 'images/icons/call.svg';
import ceIconPath from 'images/icons/ce.svg';
import chainLinkPath from 'images/icons/chain-link.svg';
import checkBadgePath from 'images/icons/check-badge.svg';
import checkIconPath from 'images/icons/check.svg';
import clockPath from 'images/icons/clock.svg';
import closeCirlcePath from 'images/icons/close-circle.svg';
import closeIconPath from 'images/icons/close-currentColor.svg';
import closePaddedIconPath from 'images/icons/close-padded.svg';
import communityIconPath from 'images/icons/community.svg';
import cornerArrowRightPath from 'images/icons/corner-arrow-right.svg';
import diamondIconPath from 'images/icons/diamond.svg';
import dotPath from 'images/icons/dot.svg';
import downThinPath from 'images/icons/down-thin.svg';
import downPath from 'images/icons/down.svg';
import envelopePath from 'images/icons/envelope.svg';
import facilityPath from 'images/icons/facility.svg';
import filePath from 'images/icons/file-24px.svg';
import firstAidPath from 'images/icons/first-aid.svg';
import glassesIconPath from 'images/icons/glasses.svg';
import graduationCapIconPath from 'images/icons/graduation-cap.svg';
import heartIconPath from 'images/icons/heart.svg';
import hideIconPath from 'images/icons/hide.svg';
import homeSimpleIconPath from 'images/icons/home-simple.svg';
import homeIconPath from 'images/icons/home.svg';
import hospitalBedPath from 'images/icons/hospital-bed.svg';
import hospitalHousePath from 'images/icons/hospital-house.svg';
import hospitalPhonePath from 'images/icons/hospital-phone.svg';
import hospitalPath from 'images/icons/hospital.svg';
import infoIconPath from 'images/icons/info.svg';
import interviewIconPath from 'images/icons/interview.svg';
import arrowLeft from 'images/icons/left-arrow.svg';
import lightningIconPath from 'images/icons/lightning.svg';
import locationPinThinIconPath from 'images/icons/location-pin-thin.svg';
import locationPinIconPath from 'images/icons/location-pin.svg';
import lockIconPath from 'images/icons/lock.svg';
import logoIconPath from 'images/icons/logo.svg';
import maximizePath from 'images/icons/maximize.svg';
import messageBubblePath from 'images/icons/message-bubble-rounded-corners.svg';
import minimizePath from 'images/icons/minimize.svg';
import minusPath from 'images/icons/minus.svg';
import moneyBagPath from 'images/icons/money-bag.svg';
import moneyBagsPath from 'images/icons/money-bags.svg';
import moneyBillPath from 'images/icons/money-bill.svg';
import moveDownPath from 'images/icons/move-down.svg';
import newWindowPath from 'images/icons/new-window.svg';
import nextPath from 'images/icons/next.svg';
import nursePath from 'images/icons/nurse.svg';
import openEnvelopePath from 'images/icons/open-envelope.svg';
import paperAirplanePath from 'images/icons/paper-airplane.svg';
import paperclipPath from 'images/icons/paperclip.svg';
import pencilPath from 'images/icons/pencil-simple.svg';
import performanceUserGraph from 'images/icons/performance-user-graph.svg';
import phoneAutoDialPath from 'images/icons/phone-auto-dial.svg';
import piggyBankIconPath from 'images/icons/piggy-bank.svg';
import pinOutlinePath from 'images/icons/pin-outline.svg';
import pinPath from 'images/icons/pin.svg';
import plusPath from 'images/icons/plus.svg';
import previousPath from 'images/icons/previous.svg';
import profileIconPath from 'images/icons/profile.svg';
import progressOnePath from 'images/icons/progress-1.svg';
import progressTwoPath from 'images/icons/progress-2.svg';
import progressThreePath from 'images/icons/progress-3.svg';
import progressFourPath from 'images/icons/progress-4.svg';
import progressEmptyPath from 'images/icons/progress-empty.svg';
import refreshPath from 'images/icons/refresh.svg';
import replyPath from 'images/icons/reply.svg';
import returnPath from 'images/icons/return.svg';
import arrowRightThin from 'images/icons/right-arrow-thin.svg';
import arrowRight from 'images/icons/right-arrow.svg';
import colorSwatchGrapePath from 'images/icons/color-swatch-grape.svg';
import colorSwatchKalePath from 'images/icons/color-swatch-kale.svg';
import colorSwatchOceanPath from 'images/icons/color-swatch-ocean.svg';
import colorSwatchRustPath from 'images/icons/color-swatch-rust.svg';
import colorSwatchSlatePath from 'images/icons/color-swatch-slate.svg';
import rightPath from 'images/icons/right.svg';
import rocketPath from 'images/icons/rocket.svg';
import scrollIconPath from 'images/icons/scroll.svg';
import sendDocumentPath from 'images/icons/send-document.svg';
import settingsPath from 'images/icons/settings.svg';
import shieldCheckIconPath from 'images/icons/shield-check.svg';
import spinnerDarkPath from 'images/icons/spinner-dark.svg';
import sproutIconPath from 'images/icons/sprout.svg';
import squareXIconPath from 'images/icons/square-x.svg';
import stairsAscendPath from 'images/icons/stairs-person-ascend.svg';
import starIconPath from 'images/icons/star.svg';
import ticketPath from 'images/icons/ticket.svg';
import dashClockFilled from 'images/icons/dash-clock-filled.svg';
import dashClockOutline from 'images/icons/dash-clock-outline.svg';
import timeSensitiveMailRejectPath from 'images/icons/time-sensitive-mail-reject.svg';
import timeSensitiveMailPath from 'images/icons/time-sensitive-mail.svg';
import trashPath from 'images/icons/trash.svg';
import truckPath from 'images/icons/truck.svg';
import upPath from 'images/icons/up.svg';
import uploadPath from 'images/icons/upload.svg';
import usersPath from 'images/icons/users.svg';
import viewIconPath from 'images/icons/view.svg';
import walletPath from 'images/icons/wallet.svg';
import wandPath from 'images/icons/wand.svg';
import xCircleIconPath from 'images/icons/x-circle.svg';
import xIconPath from 'images/icons/x.svg';
import logoPath from 'images/ih-logo.svg';

import clockFourPath from 'images/icons/clock-4.svg';
import moonPath from 'images/icons/moon.svg';
import sunMoonPath from 'images/icons/sun-moon.svg';
import sunPath from 'images/icons/sun.svg';

export const Icon = ({ title, src, ...props }) => (
  <SVG src={src} title={title} {...props} />
);
Icon.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export const AlertTriangleIcon = ({ title = 'Alert', ...props }) => (
  <Icon src={alertTriangleIconPath} title={title} {...props} />
);
AlertTriangleIcon.propTypes = {
  title: PropTypes.string,
};

export const AlertIcon = ({ title = 'Alert', ...props }) => (
  <Icon src={alertIconPath} title={title} {...props} />
);
AlertIcon.propTypes = {
  title: PropTypes.string,
};

export const AnswerIcon = ({ title = 'Answer', ...props }) => (
  <Icon src={answerIconPath} title={title} {...props} />
);
AnswerIcon.propTypes = {
  title: PropTypes.string,
};

export const AppIcon = ({ title = 'Incredible Health', ...props }) => (
  <Icon src={appIconPath} title={title} {...props} />
);
AppIcon.propTypes = {
  title: PropTypes.string,
};

export const ArchiveIcon = ({ title = 'Archive', ...props }) => (
  <Icon src={archivePath} title={title} {...props} />
);
ArchiveIcon.propTypes = {
  title: PropTypes.string,
};

export const ArrowLeftIcon = ({ title = 'Arrow Pointing Left', ...props }) => (
  <Icon src={arrowLeft} title={title} {...props} />
);
ArrowLeftIcon.propTypes = {
  title: PropTypes.string,
};

export const ArrowRightIcon = ({
  title = 'Arrow Pointing Right',
  ...props
}) => <Icon src={arrowRight} title={title} {...props} />;
ArrowRightIcon.propTypes = {
  title: PropTypes.string,
};

export const ArrowRightThinIcon = ({
  title = 'Thin Arrow Pointing Right',
  ...props
}) => <Icon src={arrowRightThin} title={title} {...props} />;
ArrowRightThinIcon.propTypes = {
  title: PropTypes.string,
};

export const ArrowSendIcon = ({ title = 'Send', ...props }) => (
  <Icon src={arrowSendPath} title={title} {...props} />
);
ArrowSendIcon.propTypes = {
  title: PropTypes.string,
};

export const ArrowUpNarrowWideTwoIcon = ({
  title = 'Arrow Up Narrow Wide Two',
  ...props
}) => <Icon src={arrowUpNarrowWideTwoPath} title={title} {...props} />;
ArrowUpNarrowWideTwoIcon.propTypes = {
  title: PropTypes.string,
};

export const ArrowUpNarrowWideFourIcon = ({
  title = 'Arrow Up Narrow Wide Four',
  ...props
}) => <Icon src={arrowUpNarrowWideFourPath} title={title} {...props} />;
ArrowUpNarrowWideFourIcon.propTypes = {
  title: PropTypes.string,
};

export const AwardIcon = ({ title = 'Award', ...props }) => (
  <Icon src={awardIconPath} title={title} {...props} />
);
AwardIcon.propTypes = {
  title: PropTypes.string,
};

export const AskQuestionIcon = ({ title = 'Ask', ...props }) => (
  <Icon src={askQuestionPath} title={title} {...props} />
);
AskQuestionIcon.propTypes = {
  title: PropTypes.string,
};

export const BackArrowThinIcon = ({ title = 'Back', ...props }) => (
  <Icon src={backArrowThinPath} title={title} {...props} />
);
BackArrowThinIcon.propTypes = {
  title: PropTypes.string,
};

export const BookOpenCheckIcon = ({ title = 'Book Open Check', ...props }) => (
  <Icon src={bookOpenCheckPath} title={title} {...props} />
);
BookOpenCheckIcon.propTypes = {
  title: PropTypes.string,
};

export const BriefcaseIcon = ({ title = 'Briefcase', ...props }) => (
  <Icon src={briefcasePath} title={title} {...props} />
);
BriefcaseIcon.propTypes = {
  title: PropTypes.string,
};

export const BusinessDealIcon = ({ title = 'Business Deal', ...props }) => (
  <Icon src={businessDealPath} title={title} {...props} />
);
BusinessDealIcon.propTypes = {
  title: PropTypes.string,
};

export const CalendarIcon = ({ title = 'Calendar', ...props }) => (
  <Icon src={calendarIconPath} title={title} {...props} />
);
CalendarIcon.propTypes = {
  title: PropTypes.string,
};

export const ChainLinkIcon = ({ title = 'Chain Link', ...props }) => (
  <Icon src={chainLinkPath} title={title} {...props} />
);

ChainLinkIcon.propTypes = {
  title: PropTypes.string,
};

export const CalendarCheckIcon = ({ title = 'Calendar Check', ...props }) => (
  <Icon src={calendarCheckIconPath} title={title} {...props} />
);
CalendarCheckIcon.propTypes = {
  title: PropTypes.string,
};

export const CalendarClockIcon = ({ title = 'Calendar Clock', ...props }) => (
  <Icon src={calendarClockIconPath} title={title} {...props} />
);
CalendarClockIcon.propTypes = {
  title: PropTypes.string,
};

export const CalendarHeartIcon = ({ title = 'Calendar Heart', ...props }) => (
  <Icon src={calendarHeartIconPath} title={title} {...props} />
);
CalendarHeartIcon.propTypes = {
  title: PropTypes.string,
};

export const CheckBadge = ({ title = 'Check Badge', ...props }) => (
  <Icon src={checkBadgePath} title={title} {...props} />
);

CheckBadge.propTypes = {
  title: PropTypes.string,
};

export const CheckIcon = ({ title = 'Check', ...props }) => (
  <Icon src={checkIconPath} title={title} {...props} />
);
CheckIcon.propTypes = {
  title: PropTypes.string,
};

export const CircleCheckHalfIcon = ({ title = 'Check', ...props }) => (
  <Icon src={circleCheckHalfPath} title={title} {...props} />
);
CircleCheckHalfIcon.propTypes = {
  title: PropTypes.string,
};

export const ContinuingEducationIcon = ({
  title = 'Continuing Education',
  ...props
}) => <Icon src={ceIconPath} title={title} {...props} />;
ContinuingEducationIcon.propTypes = {
  title: PropTypes.string,
};

export const CloseIcon = ({ title = 'Close', ...props }) => (
  <Icon src={closeIconPath} title={title} {...props} />
);
CloseIcon.propTypes = {
  title: PropTypes.string,
};

export const ClosePaddedIcon = ({ title = 'Close', ...props }) => (
  <Icon src={closePaddedIconPath} title={title} {...props} />
);
ClosePaddedIcon.propTypes = {
  title: PropTypes.string,
};

export const ClockIcon = ({ title = 'Clock', ...props }) => (
  <Icon src={clockPath} title={title} {...props} />
);
ClockIcon.propTypes = {
  title: PropTypes.string,
};

export const ClockFourIcon = ({ title = 'Clock', ...props }) => (
  <Icon src={clockFourPath} title={title} {...props} />
);
ClockFourIcon.propTypes = {
  title: PropTypes.string,
};

export const CloseIconCircle = ({ title = 'Close', ...props }) => (
  <Icon src={closeCirlcePath} title={title} {...props} />
);
CloseIconCircle.propTypes = {
  title: PropTypes.string,
};

export const CommunityIcon = ({ title = 'Community', ...props }) => (
  <Icon src={communityIconPath} title={title} {...props} />
);
CommunityIcon.propTypes = {
  title: PropTypes.string,
};

export const CornerArrowRightIcon = ({
  title = 'Corner arrow right',
  ...props
}) => <Icon src={cornerArrowRightPath} title={title} {...props} />;
CornerArrowRightIcon.propTypes = {
  title: PropTypes.string,
};

export const DiamondIcon = ({ title = 'Diamond', ...props }) => (
  <Icon src={diamondIconPath} title={title} {...props} />
);
DiamondIcon.propTypes = {
  title: PropTypes.string,
};

export const DotIcon = ({ title = 'Dot', ...props }) => (
  <Icon src={dotPath} title={title} {...props} />
);
DotIcon.propTypes = {
  title: PropTypes.string,
};

export const DownIcon = ({ title = 'Down', ...props }) => (
  <Icon src={downPath} title={title} {...props} />
);
DownIcon.propTypes = {
  title: PropTypes.string,
};

export const DownThinIcon = ({ title = 'Down', ...props }) => (
  <Icon src={downThinPath} title={title} {...props} />
);
DownThinIcon.propTypes = {
  title: PropTypes.string,
};

export const EnvelopeIcon = ({ title = 'Envelope', ...props }) => (
  <Icon src={envelopePath} title={title} {...props} />
);
EnvelopeIcon.propTypes = {
  title: PropTypes.string,
};

export const FacilityIcon = ({ title = 'Facility', ...props }) => (
  <Icon src={facilityPath} title={title} {...props} />
);
FacilityIcon.propTypes = {
  title: PropTypes.string,
};

export const FileIcon = ({ title = 'File', ...props }) => (
  <Icon src={filePath} title={title} {...props} />
);
FileIcon.propTypes = {
  title: PropTypes.string,
};

export const FilterIcon = ({ title = 'Filter', ...props }) => (
  <Icon src={filterPath} title={title} {...props} />
);
FilterIcon.propTypes = {
  title: PropTypes.string,
};

export const FirstAidIcon = ({ title = 'First Aid', ...props }) => (
  <Icon src={firstAidPath} title={title} {...props} />
);
FirstAidIcon.propTypes = {
  title: PropTypes.string,
};

export const FollowIcon = ({ title = 'Follow', ...props }) => (
  <Icon src={followIconPath} title={title} {...props} />
);
FollowIcon.propTypes = {
  title: PropTypes.string,
};

export const GlassesIcon = ({ title = 'Glasses', ...props }) => (
  <Icon src={glassesIconPath} title={title} {...props} />
);
GlassesIcon.propTypes = {
  title: PropTypes.string,
};

export const GraduationCapIcon = ({ title = 'Graduation Cap', ...props }) => (
  <Icon src={graduationCapIconPath} title={title} {...props} />
);
GraduationCapIcon.propTypes = {
  title: PropTypes.string,
};

export const HeartIcon = ({ title = 'Heart', ...props }) => (
  <Icon src={heartIconPath} title={title} {...props} />
);
HeartIcon.propTypes = {
  title: PropTypes.string,
};

export const HideIcon = ({ title = 'Hide', ...props }) => (
  <Icon src={hideIconPath} title={title} {...props} />
);
HideIcon.propTypes = {
  title: PropTypes.string,
};

export const HomeIcon = ({ title = 'Home', ...props }) => (
  <Icon src={homeIconPath} title={title} {...props} />
);
HomeIcon.propTypes = {
  title: PropTypes.string,
};

export const HomeSimpleIcon = ({ title = 'Home Simple', ...props }) => (
  <Icon src={homeSimpleIconPath} title={title} {...props} />
);
HomeSimpleIcon.propTypes = {
  title: PropTypes.string,
};

export const HospitalIcon = ({ title = 'Hospital', ...props }) => (
  <Icon src={hospitalPath} title={title} {...props} />
);
HospitalIcon.propTypes = {
  title: PropTypes.string,
};

export const HospitalBedIcon = ({ title = 'Hospital Bed', ...props }) => (
  <Icon src={hospitalBedPath} title={title} {...props} />
);
HospitalBedIcon.propTypes = {
  title: PropTypes.string,
};

export const HospitalHouseIcon = ({ title = 'Hospital House', ...props }) => (
  <Icon src={hospitalHousePath} title={title} {...props} />
);
HospitalHouseIcon.propTypes = {
  title: PropTypes.string,
};

export const HospitalPhoneIcon = ({ title = 'Hospital Phone', ...props }) => (
  <Icon src={hospitalPhonePath} title={title} {...props} />
);
HospitalPhoneIcon.propTypes = {
  title: PropTypes.string,
};

export const InfoIcon = ({ title = 'Information', ...props }) => (
  <Icon src={infoIconPath} title={title} {...props} />
);
InfoIcon.propTypes = {
  title: PropTypes.string,
};

export const InterviewIcon = ({ title = 'Interview', ...props }) => (
  <Icon src={interviewIconPath} title={title} {...props} />
);
InterviewIcon.propTypes = {
  title: PropTypes.string,
};

export const LightningIcon = ({ title = 'Lightning', ...props }) => (
  <Icon src={lightningIconPath} title={title} {...props} />
);
LightningIcon.propTypes = {
  title: PropTypes.string,
};

export const LocationPinIcon = ({ title = 'Location', ...props }) => (
  <Icon src={locationPinIconPath} title={title} {...props} />
);
LocationPinIcon.propTypes = {
  title: PropTypes.string,
};

export const LocationPinThinIcon = ({ title = 'Location', ...props }) => (
  <Icon src={locationPinThinIconPath} title={title} {...props} />
);
LocationPinThinIcon.propTypes = {
  title: PropTypes.string,
};

export const LockIcon = ({ title = 'Lock', ...props }) => (
  <Icon src={lockIconPath} title={title} {...props} />
);
LockIcon.propTypes = {
  title: PropTypes.string,
};

export const Logo = ({ title = 'Incredible Health', ...props }) => (
  <Icon src={logoPath} title={title} {...props} />
);
Logo.propTypes = {
  title: PropTypes.string,
};

export const LogoIcon = ({ title = 'Incredible Health', ...props }) => (
  <Icon src={logoIconPath} title={title} {...props} />
);
LogoIcon.propTypes = {
  title: PropTypes.string,
};

export const MaximizeIcon = ({ title = 'Maximize', ...props }) => (
  <Icon src={maximizePath} title={title} {...props} />
);
MaximizeIcon.propTypes = {
  title: PropTypes.string,
};

export const MessageBubbleIcon = ({ title = 'Message Bubble', ...props }) => (
  <Icon src={messageBubblePath} title={title} {...props} />
);
MessageBubbleIcon.propTypes = {
  title: PropTypes.string,
};

export const MinimizeIcon = ({ title = 'Minimize', ...props }) => (
  <Icon src={minimizePath} title={title} {...props} />
);
MinimizeIcon.propTypes = {
  title: PropTypes.string,
};

export const MinusIcon = ({ title = 'Minus', ...props }) => (
  <Icon src={minusPath} title={title} {...props} />
);
MinusIcon.propTypes = {
  title: PropTypes.string,
};

export const MoneyBagIcon = ({ title = 'Money Bag', ...props }) => (
  <Icon src={moneyBagPath} title={title} {...props} />
);
MoneyBagIcon.propTypes = {
  title: PropTypes.string,
};

export const MoneyBagsIcon = ({ title = 'Money Bags', ...props }) => (
  <Icon src={moneyBagsPath} title={title} {...props} />
);
MoneyBagsIcon.propTypes = {
  title: PropTypes.string,
};

export const MoneyBillIcon = ({ title = 'Money Bill', ...props }) => (
  <Icon src={moneyBillPath} title={title} {...props} />
);
MoneyBillIcon.propTypes = {
  title: PropTypes.string,
};

export const MoonIcon = ({ title = 'Moon', ...props }) => (
  <Icon src={moonPath} title={title} {...props} />
);
MoonIcon.propTypes = {
  title: PropTypes.string,
};

export const MoveDownIcon = ({ title = 'Move Down', ...props }) => (
  <Icon src={moveDownPath} title={title} {...props} />
);
MoveDownIcon.propTypes = {
  title: PropTypes.string,
};

export const NextIcon = ({ title = 'Next', ...props }) => (
  <Icon src={nextPath} title={title} {...props} />
);
NextIcon.propTypes = {
  title: PropTypes.string,
};

export const NewWindow = ({ title = 'New Window', ...props }) => (
  <Icon src={newWindowPath} title={title} {...props} />
);
NewWindow.propTypes = {
  title: PropTypes.string,
};

export const NurseIcon = ({ title = 'Nurse', ...props }) => (
  <Icon src={nursePath} title={title} {...props} />
);
NurseIcon.propTypes = {
  title: PropTypes.string,
};

export const OpenEnvelopeIcon = ({ title = 'Open Envelope', ...props }) => (
  <Icon src={openEnvelopePath} title={title} {...props} />
);
OpenEnvelopeIcon.propTypes = {
  title: PropTypes.string,
};

export const PaperAirplaneIcon = ({ title = 'Paper Airplane', ...props }) => (
  <Icon src={paperAirplanePath} title={title} {...props} />
);
PaperAirplaneIcon.propTypes = {
  title: PropTypes.string,
};

export const PaperclipIcon = ({ title = 'Paperclip', ...props }) => (
  <Icon src={paperclipPath} title={title} {...props} />
);
PaperclipIcon.propTypes = {
  title: PropTypes.string,
};

export const PerformanceUserGraphIcon = ({
  title = 'Performance User Graph',
  ...props
}) => <Icon src={performanceUserGraph} title={title} {...props} />;
PerformanceUserGraphIcon.propTypes = {
  title: PropTypes.string,
};

export const PencilIcon = ({ title = 'Pencil', ...props }) => (
  <Icon src={pencilPath} title={title} {...props} />
);
PencilIcon.propTypes = {
  title: PropTypes.string,
};

export const PhoneAutoDialIcon = ({ title = 'Phone Auto-Dial', ...props }) => (
  <Icon src={phoneAutoDialPath} title={title} {...props} />
);
PhoneAutoDialIcon.propTypes = {
  title: PropTypes.string,
};

export const PiggyBankIcon = ({ title = 'Piggy Bank', ...props }) => (
  <Icon src={piggyBankIconPath} title={title} {...props} />
);
PiggyBankIcon.propTypes = {
  title: PropTypes.string,
};

export const PhoneCallIcon = ({ title = 'Phone Call', ...props }) => (
  <Icon src={callIconPath} title={title} {...props} />
);
PhoneCallIcon.propTypes = {
  title: PropTypes.string,
};

export const PinIcon = ({ title = 'Pin', ...props }) => (
  <Icon src={pinPath} title={title} {...props} />
);
PinIcon.propTypes = {
  title: PropTypes.string,
};

export const PinOutlineIcon = ({ title = 'Pin', ...props }) => (
  <Icon src={pinOutlinePath} title={title} {...props} />
);
PinOutlineIcon.propTypes = {
  title: PropTypes.string,
};

export const PlusIcon = ({ title = 'Plus', ...props }) => (
  <Icon src={plusPath} title={title} {...props} />
);
PlusIcon.propTypes = {
  title: PropTypes.string,
};

export const PreviousIcon = ({ title = 'Previous', ...props }) => (
  <Icon src={previousPath} title={title} {...props} />
);
PreviousIcon.propTypes = {
  title: PropTypes.string,
};

export const ProfileIcon = ({ title = 'Profile', ...props }) => (
  <Icon src={profileIconPath} title={title} {...props} />
);
ProfileIcon.propTypes = {
  title: PropTypes.string,
};

export const ProgressEmptyIcon = ({
  title = 'Progress step zero',
  ...props
}) => <Icon src={progressEmptyPath} title={title} {...props} />;
ProgressEmptyIcon.propTypes = {
  title: PropTypes.string,
};

export const ProgressOneIcon = ({
  title = 'Progress first step',
  ...props
}) => <Icon src={progressOnePath} title={title} {...props} />;
ProgressOneIcon.propTypes = {
  title: PropTypes.string,
};

export const ProgressTwoIcon = ({
  title = 'Progress second step',
  ...props
}) => <Icon src={progressTwoPath} title={title} {...props} />;
ProgressTwoIcon.propTypes = {
  title: PropTypes.string,
};

export const ProgressThreeIcon = ({
  title = 'Progress third step',
  ...props
}) => <Icon src={progressThreePath} title={title} {...props} />;
ProgressThreeIcon.propTypes = {
  title: PropTypes.string,
};

export const ProgressFourIcon = ({
  title = 'Progress last step',
  ...props
}) => <Icon src={progressFourPath} title={title} {...props} />;
ProgressFourIcon.propTypes = {
  title: PropTypes.string,
};

export const ReturnIcon = ({ title = 'Return', ...props }) => (
  <Icon src={returnPath} title={title} {...props} />
);
ReturnIcon.propTypes = {
  title: PropTypes.string,
};

export const RefreshIcon = ({ title = 'Refresh', ...props }) => (
  <Icon src={refreshPath} title={title} {...props} />
);
RefreshIcon.propTypes = {
  title: PropTypes.string,
};

export const ReplyIcon = ({ title = 'Reply', ...props }) => (
  <Icon src={replyPath} title={title} {...props} />
);
ReplyIcon.propTypes = {
  title: PropTypes.string,
};

export const ColorSwatchGrapeIcon = ({ title = 'Grape', ...props }) => (
  <Icon src={colorSwatchGrapePath} title={title} {...props} />
);
ColorSwatchGrapeIcon.propTypes = {
  title: PropTypes.string,
};

export const ColorSwatchKaleIcon = ({ title = 'Grape', ...props }) => (
  <Icon src={colorSwatchKalePath} title={title} {...props} />
);
ColorSwatchKaleIcon.propTypes = {
  title: PropTypes.string,
};

export const ColorSwatchOceanIcon = ({ title = 'Grape', ...props }) => (
  <Icon src={colorSwatchOceanPath} title={title} {...props} />
);
ColorSwatchOceanIcon.propTypes = {
  title: PropTypes.string,
};

export const ColorSwatchRustIcon = ({ title = 'Rust', ...props }) => (
  <Icon src={colorSwatchRustPath} title={title} {...props} />
);
ColorSwatchRustIcon.propTypes = {
  title: PropTypes.string,
};

export const ColorSwatchSlateIcon = ({ title = 'Rust', ...props }) => (
  <Icon src={colorSwatchSlatePath} title={title} {...props} />
);
ColorSwatchSlateIcon.propTypes = {
  title: PropTypes.string,
};

export const RightIcon = ({ title = 'Right', ...props }) => (
  <Icon src={rightPath} title={title} {...props} />
);
RightIcon.propTypes = {
  title: PropTypes.string,
};

export const RocketIcon = ({ title = 'Rocket', ...props }) => (
  <Icon src={rocketPath} title={title} {...props} />
);
RocketIcon.propTypes = {
  title: PropTypes.string,
};

export const ScrollIcon = ({ title = 'Scroll', ...props }) => (
  <Icon src={scrollIconPath} title={title} {...props} />
);
ScrollIcon.propTypes = {
  title: PropTypes.string,
};

export const SearchIcon = ({ title = 'Search', ...props }) => (
  <Icon src={searchPath} title={title} {...props} />
);
SearchIcon.propTypes = {
  title: PropTypes.string,
};

export const SendDocumentIcon = ({ title = 'Send Document', ...props }) => (
  <Icon src={sendDocumentPath} title={title} {...props} />
);
SendDocumentIcon.propTypes = {
  title: PropTypes.string,
};

export const SettingsIcon = ({ title = 'Settings', ...props }) => (
  <Icon src={settingsPath} title={title} {...props} />
);
SettingsIcon.propTypes = {
  title: PropTypes.string,
};

export const ShieldCheckIcon = ({ title = 'Shield Check', ...props }) => (
  <Icon src={shieldCheckIconPath} title={title} {...props} />
);
ShieldCheckIcon.propTypes = {
  title: PropTypes.string,
};

export const SpinnerDarkIcon = ({ title = 'Spinner Dark', ...props }) => (
  <Icon src={spinnerDarkPath} title={title} {...props} />
);
SpinnerDarkIcon.propTypes = {
  title: PropTypes.string,
};

export const SproutIcon = ({ title = 'Sprout', ...props }) => (
  <Icon src={sproutIconPath} title={title} {...props} />
);
SproutIcon.propTypes = {
  title: PropTypes.string,
};

export const SquareXIcon = ({ title = 'Square X', ...props }) => (
  <Icon src={squareXIconPath} title={title} {...props} />
);
SquareXIcon.propTypes = {
  title: PropTypes.string,
};

export const StarIcon = ({ title = 'Star', ...props }) => (
  <Icon src={starIconPath} title={title} {...props} />
);
StarIcon.propTypes = {
  title: PropTypes.string,
};

export const StairsAscendIcon = ({ title = 'Stairs Ascend', ...props }) => (
  <Icon src={stairsAscendPath} title={title} {...props} />
);
StairsAscendIcon.propTypes = {
  title: PropTypes.string,
};

export const SunIcon = ({ title = 'Sun', ...props }) => (
  <Icon src={sunPath} title={title} {...props} />
);
SunIcon.propTypes = {
  title: PropTypes.string,
};

export const SunMoonIcon = ({ title = 'Sun Moon', ...props }) => (
  <Icon src={sunMoonPath} title={title} {...props} />
);
SunMoonIcon.propTypes = {
  title: PropTypes.string,
};

export const TicketIcon = ({ title = 'Ticket', ...props }) => (
  <Icon src={ticketPath} title={title} {...props} />
);
TicketIcon.propTypes = {
  title: PropTypes.string,
};

export const DashClockFilledIcon = ({
  title = 'Filled clock with dash lines',
  ...props
}) => <Icon src={dashClockFilled} title={title} {...props} />;
DashClockFilledIcon.propTypes = {
  title: PropTypes.string,
};

export const DashClockOutlineIcon = ({
  title = 'Outlined clock with dash lines',
  ...props
}) => <Icon src={dashClockOutline} title={title} {...props} />;
DashClockOutlineIcon.propTypes = {
  title: PropTypes.string,
};

export const TimeSensitiveMailIcon = ({
  title = 'Mail with clock',
  ...props
}) => <Icon src={timeSensitiveMailPath} title={title} {...props} />;
TimeSensitiveMailIcon.propTypes = {
  title: PropTypes.string,
};

export const TimeSensitiveMailRejectIcon = ({
  title = 'Mail with stop sign',
  ...props
}) => <Icon src={timeSensitiveMailRejectPath} title={title} {...props} />;
TimeSensitiveMailRejectIcon.propTypes = {
  title: PropTypes.string,
};

export const TrashIcon = ({ title = 'Trash', ...props }) => (
  <Icon src={trashPath} title={title} {...props} />
);
TrashIcon.propTypes = {
  title: PropTypes.string,
};

export const TruckIcon = ({ title = 'Truck', ...props }) => (
  <Icon src={truckPath} title={title} {...props} />
);
TruckIcon.propTypes = {
  title: PropTypes.string,
};

export const UnfollowIcon = ({ title = 'Unfollow', ...props }) => (
  <Icon src={unfollowIconPath} title={title} {...props} />
);
UnfollowIcon.propTypes = {
  title: PropTypes.string,
};

export const UpIcon = ({ title = 'Up', ...props }) => (
  <Icon src={upPath} title={title} {...props} />
);
UpIcon.propTypes = {
  title: PropTypes.string,
};

export const UploadIcon = ({ title = 'Upload', ...props }) => (
  <Icon src={uploadPath} title={title} {...props} />
);
UploadIcon.propTypes = {
  title: PropTypes.string,
};

export const UsersIcon = ({ title = 'Users', ...props }) => (
  <Icon src={usersPath} title={title} {...props} />
);
UsersIcon.propTypes = {
  title: PropTypes.string,
};

export const ViewIcon = ({ title = 'View', ...props }) => (
  <Icon src={viewIconPath} title={title} {...props} />
);
ViewIcon.propTypes = {
  title: PropTypes.string,
};

export const Wallet = ({ title = 'Wallet', ...props }) => (
  <Icon src={walletPath} title={title} {...props} />
);
Wallet.propTypes = {
  title: PropTypes.string,
};

export const WandIcon = ({ title = 'Wand', ...props }) => (
  <Icon src={wandPath} title={title} {...props} />
);
WandIcon.propTypes = {
  title: PropTypes.string,
};

export const XIcon = ({ title = 'X Circle', ...props }) => (
  <Icon src={xIconPath} title={title} {...props} />
);
XIcon.propTypes = {
  title: PropTypes.string,
};

export const XCircleIcon = ({ title = 'X Circle', ...props }) => (
  <Icon src={xCircleIconPath} title={title} {...props} />
);
XCircleIcon.propTypes = {
  title: PropTypes.string,
};
