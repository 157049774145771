import React, { useEffect, useState } from 'react';
import { useStyletron } from 'shared/components/ihcl/styled';

export type Breakpoint = 'small' | 'medium' | 'large';
type Dimensions = { width: number; height: number };

type ViewportContext = {
  breakpoint: Breakpoint;
  dimensions: Dimensions;
};

const Context = React.createContext<ViewportContext>({
  breakpoint: null,
  dimensions: { width: 0, height: 0 },
});

// eslint-disable-next-line react/require-default-props
export default ({ children }: React.ProviderProps<Breakpoint>) => {
  const [, theme] = useStyletron();
  const determineBreakpoint = () => {
    if (window.innerWidth > theme.breakpoints.large) {
      return 'large';
    }
    if (window.innerWidth > theme.breakpoints.medium) {
      return 'medium';
    }

    return 'small';
  };
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(
    determineBreakpoint()
  );
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setBreakpoint(determineBreakpoint());
        setDimensions({ width: window.innerWidth, height: window.innerHeight });
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return null;
  });

  return (
    <Context.Provider
      value={{
        dimensions,
        breakpoint,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useViewportContext = () => React.useContext(Context);
